import React, { useEffect, useState } from "react"
//react form
import { FormProvider, useForm, Controller } from "react-hook-form"
//controls
import {
  Col,
  Label,
  Row,
  Modal,
  ModalBody,
  InputGroup,
  FormFeedback,
  ModalHeader,
  ModalFooter,
} from "reactstrap"
import Flatpickr from "react-flatpickr"
import { Link, useNavigate, useParams } from "react-router-dom"
//import { AiOutlinePlus } from 'react-icons/ai';
import "./weeklytime.css"
//redux
import { useSelector, useDispatch } from "react-redux"
//custom hooks
import { useDeepCompareEffect } from "hooks"
//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
//Date filter
import Moment from "moment"

import NoteModal from "./../modal/NoteModal"
import DeleteModal from "./../../../../components/Common/DeleteModal"
import { ToastContainer } from "react-toastify"
import NoteDetail from "./NoteDetail"
import DOMPurify from "dompurify"
import {
  getCurriculumSchedulesByCurriculum as onGetCurriculumSchedulesByCurriculum,
  deleteCurriculumSchedule as onDeleteCurriculumSchedule,
} from "store/actions"

const WeeklyTimetable = ({ curriculum }) => {
  const dispatch = useDispatch()
  const routeParams = useParams()
  const [deleteModal, setDeleteModal] = useState(false)
  const [noteModal, setNoteModal] = useState(false)
  const [edit, setEdit] = useState(false)
  const [selectedDay, setSelectedDay] = useState("")
  const [selectedTime, setSelectedTime] = useState("")
  const [curriculumscheduleID, setCurriculumScheduleID] = useState(0)
  // Initialize timetable with default values
  const { curriculumschedules } = useSelector(state => state.curriculum)

  const defaultSchedule = [
    {
      id: 1,
      Time: "08:00 AM",
      Monday: "",
      Tuesday: "",
      Wednesday: "",
      Thursday: "",
      Friday: "",
      Saturday: "",
      Sunday: "",
      Remove: "",
    },
  ]
  const [timetable, setTimetable] = useState([...defaultSchedule])

  const schema = yup.object().shape({
    Time: yup.string().required("Required"),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      Time: "",
    },
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, watch, setValue, getValues, trigger } =
    methods
  const { errors, isValid } = formState

  useDeepCompareEffect(() => {
    debugger
    const { id } = routeParams
    function updateCurriculumState() {
      setTimetable([...defaultSchedule])
      dispatch(onGetCurriculumSchedulesByCurriculum(id))
    }

    updateCurriculumState()
    if (curriculumschedules && curriculumschedules?.length > 0) {
      debugger
      // setTimetable([ ...curriculumschedules])
      if (curriculumschedules[curriculumschedules?.length - 1] != undefined) {
        setTimetable([...curriculumschedules])
      } else {
        setTimetable([...defaultSchedule])
      }
      //console.log(timetable)
    }
    //  console.log(curriculumschedules)
  }, [dispatch, curriculumschedules])

  useEffect(() => {}, [
    timetable,
    noteModal,
    edit,
    selectedDay,
    selectedTime,
    curriculumschedules,
    curriculumscheduleID,
  ])

  // show,
  // onEdit,
  // selectedDay,
  // selectedTime,
  // curriculumscheduleID,
  // curriculum,

  // Function to add an event to the timetable
  const addEvent = (Time, day, event) => {
    setTimetable(prevTimetable => {
      return prevTimetable.map(row => {
        if (row.Time === Time) {
          return { ...row, [day]: event }
        } else {
          return row
        }
      })
    })
  }

  // Function to handle cell click
  const handleCellClick = (time, day, index, id) => {
    debugger

    if (day == "Remove") {
      setCurriculumScheduleID(id)
      setDeleteModal(true)

      //  setTimetable([...timetable.filter((item, i) => i != index)])
    } else {
      // const newEvent = prompt("Enter event:")
      // if (newEvent !== null) {
      //   addEvent(time, day, newEvent)
      // }
      setSelectedDay(day)
      setSelectedTime(time)
      if (timetable[index][day] != "") {
        //const obj = { Description: timetable[index][day] }
        setEdit(true)
        setCurriculumScheduleID(timetable[index]?.id)
      } else {
        setEdit(false)
      }

      setNoteModal(true)
    }
  }

  // Function to handle inline time editing
  const handleTimeChange = (index, newTime) => {

    setTimetable(prevTimetable => {
      const newTimetable = [...prevTimetable]
      newTimetable[index].Time = newTime
      return newTimetable
    })
  }

  // Function to add a new row
  const addRow = () => {
    debugger
    const newId =
      timetable?.length == 0 ? 1 : timetable[timetable.length - 1]?.id + 1
    const newRow = {
      id: newId,
      Time: "08:00 AM",
      Monday: "",
      Tuesday: "",
      Wednesday: "",
      Thursday: "",
      Friday: "",
      Saturday: "",
      Sunday: "",
      Remove: "",
    }

    if (timetable?.length == 0) {
      setTimetable([newRow])
    } else {
      setTimetable(prevTimetable => [...prevTimetable, newRow])
    }
  }

  const onCloseModal = () => {
    setSelectedDay("")
    setSelectedTime("")
    setNoteModal(false)
  }
  const handleDelete = () => {
    debugger
    if (curriculumscheduleID > 0) {
      dispatch(onDeleteCurriculumSchedule(curriculumscheduleID))
      setDeleteModal(false)
      setCurriculumScheduleID(0)
    }
  }
  // Render the timetable
  return (
    <>
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDelete}
          onCloseClick={() => setDeleteModal(false)}
        />
      )}
      <NoteModal
        show={noteModal}
        onEdit={edit}
        selectedDay={selectedDay}
        selectedTime={selectedTime}
        curriculumscheduleID={curriculumscheduleID}
        curriculum={curriculum}
        onCloseClick={() => onCloseModal()}
        header={edit == false ? "Add Notes" : "Update Notes"}
      />
      <FormProvider {...methods}>
        <table className="timetable">
          <thead>
            <tr>
              <th>Time</th>
              <th>Monday</th>
              <th>Tuesday</th>
              <th>Wednesday</th>
              <th>Thursday</th>
              <th>Friday</th>
              <th>Saturday</th>
              <th>Sunday</th>
              <th>Remove</th>
            </tr>
          </thead>
          <tbody>
            {timetable &&
              timetable.map((row, index) => (
                <tr key={index}>
                  <td width={140}>
                    {/* <input
                      type="text"
                      value={row?.Time}
                      onChange={e => handleTimeChange(index, e.target.value)}
                      className="time-input form-control"
                    /> */}
                    {/* {row?.Time} */}
                    <Controller
                      //name="Time"
                      name={`timeInputs[${index}].Time`}
                      control={control}
                      defaultValue={row?.Time}
                      render={({ field }) => (
                        <>
                          <InputGroup>
                            <Flatpickr
                              {...field}
                              className="form-control d-block"
                              //id="Time"
                              name={`timeInputsid[${index}].Time`}
                              options={{
                                enableTime: true,
                                noCalendar: true,
                                dateFormat: "h:i K",
                              }}
                              onChange={(selectedTimes, timeStr, instance) => {
                              //  alert(timeStr);
                                setValue(
                                  "Time",
                                  Moment(selectedTimes[0]).format("h:mm a")
                                )
                                setSelectedTime(timeStr)
                                handleTimeChange(index, timeStr)
                              }}
                              // value={row?.Time}
                              
                              required
                            />
                            <div className="input-group-append">
                              <span className="input-group-text">
                                <i className="mdi mdi-clock-outline" />
                              </span>
                            </div>
                            {errors?.Time?.message ? (
                              <FormFeedback type="invalid" className="d-block">
                                {errors?.Time?.message}
                              </FormFeedback>
                            ) : null}
                          </InputGroup>
                        </>
                      )}
                    />
                  </td>
                  {[
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                    "Sunday",
                    "Remove",
                  ].map(day => (
                    <td
                      key={day}
                      className="cell"
                      onClick={() =>
                        handleCellClick(row?.Time, day, index, row?.id)
                      }
                    >
                      {day == "Remove" ? "x" : ""}
                      {/* {row[day]} */}

                      {day == "Remove" ? (
                        ""
                      ) : (
                        // <NoteDetail scheduleID={row?.id} day={day} />
                        <>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: day != undefined && row[day],
                            }}
                          />
                        </>
                      )}
                      <span className="add-event-icon" title="Add Note">
                        {/* <AiOutlinePlus /> */}
                      </span>
                    </td>
                  ))}
                </tr>
              ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="9" className="add-row" onClick={addRow}>
                Add Row
              </td>
            </tr>
          </tfoot>
        </table>
      </FormProvider>
      <ToastContainer />
    </>
  )
}

export default WeeklyTimetable
