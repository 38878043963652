import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../../components/Common/TableContainer"
import * as moment from "moment"

//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import DeleteModal from "../../../components/Common/DeleteModal"
import WeeklyTimeTable from "./pages/WeeklyTimeTable";
import {
  getAllNote as onGetAllNote,
  getNoteDetail as onGetNoteDetail,
  deleteNote as onDeleteNote,
} from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import {
  Col,
  Row,
  Table,
  Card,
  CardBody,
  Button,
  Badge,
  UncontrolledTooltip,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

import Spinners from "components/Common/Spinner"
import { ToastContainer } from "react-toastify"
import NoteModal from "./modal/NoteModal"
import { useDeepCompareEffect } from "hooks"
import Moment from "moment"
// import EnrollModal from "./modal/EnrollModal"

function Notes() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [toggleMenu, setToggleMenu] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [noteModal, setNoteModal] = useState(false)
  // const [enrollModal, setEnrollModal] = useState(false)
  const [note, setNote] = useState(false)
  const [edit, setEdit] = useState(false)

  useDeepCompareEffect(() => {
    dispatch(onGetAllNote())
  }, [dispatch])

  //////////////////PROGRAM SCHEDULE STATE/////////////////////
  const { notes, loading } = useSelector(state => state.note)
  const [isLoading, setLoading] = useState(loading)
  //////////////////////////////////////

  const handleAddClick = () => {
    setNote(null)
    setEdit(false)
    setNoteModal(true)
    navigate("/learning/notes/new")
  }

  const onClickEdit = arg => {
    //dispatch(onGetNoteDetail(arg))
   // setNote(arg)
   // setEdit(true)
    //setNoteModal(true)
  }

  const onClickDelete = arg => {
    setNote(arg)
    setDeleteModal(true)
  }

  const onCloseModal = () => {
    setNoteModal(false)
  }

  const handleDelete = () => {
    if (note && note.NoteID) {
      dispatch(onDeleteNote(note.NoteID))
      setDeleteModal(false)
    }
  }

  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y")
    return date1
  }
  const columns = useMemo(
    () => [
      {
        Header: "Title",
        accessor: "Title",
        Cell: cellProps => {
          return <>{cellProps.row.original.Title}</>
        },
      },
      
      {
        Header: "Program",
        accessor: "ProgramName",
        Cell: cellProps => {
          return cellProps.value ? cellProps.value : ""
        },
      },

      // {
      //   Header: "Rate",
      //   accessor: "Rate",
      //   Cell: cellProps => {
      //     return cellProps.value
      //       ? "$" + cellProps.value.toFixed(2).toString()
      //       : ""
      //   },
      // },
      // {
      //   Header: "Shared",
      //   accessor: "IsShared",
      //   Cell: cellProps => {
      //     return cellProps.value == true ? "Yes" : "No"
      //   },
      // },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
             
                  to={"/learning/notes/" + cellProps.row.original.RowID}
                className="text-success"
               // onClick={() => {
               //   onClickEdit(cellProps.row.original)
               // }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  onClickDelete(cellProps.row.original)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDelete}
          onCloseClick={() => setDeleteModal(false)}
        />
      )}
      {noteModal && (
        <NoteModal
          show={noteModal}
          onEdit={edit}
          note={note}
          onCloseClick={() => onCloseModal()}
        />
      )}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Learning" breadcrumbItem="Notes/Activities List" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <div className="text-sm-end">
                    <Button
                      type="button"
                      color="primary"
                      className="btn "
                      onClick={handleAddClick}
                    >
                      <i className="mdi mdi-plus-circle-outline me-1" />
                      Add Notes
                    </Button>
                  </div>
                  {isLoading ? (
                    <Spinners setLoading={setLoading} />
                  ) : (
                    <TableContainer
                      columns={columns}
                      data={notes}
                      isGlobalFilter={true}
                      customPageSize={10}
                      isPagination={true}
                      filterable={false}
                      iscustomPageSizeOptions={true}
                      tableClass="align-middle table-nowrap table-check"
                      theadClass="table-light"
                      pagination="pagination pagination-rounded justify-content-end mb-2"
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}
Notes.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Notes
