import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
//controls
import { Col, Label, Row, Input, FormFeedback, InputGroup } from "reactstrap"
import Flatpickr from "react-flatpickr"

//react form
import {
  FormProvider,
  useForm,
  Controller,
  useFieldArray,
} from "react-hook-form"
import Select from "react-select"
import { createSelector } from "reselect"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

//custom hooks
import { useDeepCompareEffect } from "hooks"

import Switch from "react-switch"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"

import { isEmpty } from "lodash"

import {
  addNote as onAddNote,
  updateNote as onUpdateNote,
  getPrograms as onGetPrograms,
} from "store/actions"
 import PageModal from "components/Common/PageModal"
import Moment from "moment"





const formdefault = {
  NoteID: 0,
  Title: "",
}

const NoteModal = ({ show, onEdit, note, onCloseClick }) => {
  const dispatch = useDispatch()
 // const [types, setTypes] = useState([])
  //const [scheduleArray, setScheduleArray] = useState([])
  //const { note } = useSelector(state => state.note)

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    NoteID: yup.number(),
    Title: yup.string().required("Required"),
   // Rate: yup.string().required("Required"),
    //IsShared: yup.bool()
   
    
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: formdefault,
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, setValue, getValues, watch, trigger } =    methods
  const { errors, isValid } = formState




  useEffect(() => {
    if (onEdit) {
     
        const response = {
          ...note
        }
        reset(response)
      
    } else {
      //setScheduleArray([])
      reset(formdefault)
    }
  }, [note, onEdit])

  const onSaveClick = () => {
    trigger()
    if (isValid) {
      //console.log(getValues())
      if (!onEdit) {
        dispatch(onAddNote(getValues()))
      } else {
        console.log(getValues())
        dispatch(onUpdateNote(getValues()))
      }
      onCloseClick()
    }
  }

  const onClosed = () => {
    reset(formdefault)
  }

  function getDifference(array1, array2) {
    return array1.filter(o1 => {
      return !array2.some(o2 => {
        return o1 === o2.DayID
      })
    })
  }

  
  return (
    <PageModal
      show={show}
      onCloseClick={onCloseClick}
      onSaveClick={() => onSaveClick()}
      onClosed={() => onClosed()}
      header="Add Note"
      size="lg"
    >
      <FormProvider {...methods}>
        <Row>
          <Col lg={12}>
            <div className="mb-3">
              <Label>Title</Label>
              <Controller
                name="Title"
                control={control}
                render={({ field }) => (
                  <>
                    <Input
                      {...field}
                      id="Title"
                      type="text"
                      required
                      invalid={!!errors.Title}
                    />
                    {errors?.Title?.message ? (
                      <FormFeedback type="invalid" className="d-block">
                        {errors?.Title?.message}
                      </FormFeedback>
                    ) : null}
                  </>
                )}
              />
            </div>
            {/* <div className="mb-3">
              <Label>Program</Label>
              <Controller
                name="Programs"
                control={control}
                render={({ field }) => (
                  <>
                    <Select
                      {...field}
                      id="Programs"
                      options={programs.filter(
                        program => program.IsActive == 1
                      )}
                      getOptionLabel={option => option.Name}
                      getOptionValue={option => option.ProgramID}
                      required
                      aria-invalid={!!errors.Staffs}
                      classNamePrefix="select2-selection"
                    />
                    {errors?.Programs?.message ? (
                      <FormFeedback type="invalid" className="d-block">
                        {errors?.Programs?.message}
                      </FormFeedback>
                    ) : null}
                  </>
                )}
              />
            </div> */}
            {/* <div className="mb-3">
              <Label>Shared with Parent</Label>
              <div>
                <Controller
                  name="IsShared"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Switch
                        {...field}
                        id="IsShared"
                        checked={field.value}
                        uncheckedIcon={<Offsymbol />}
                        checkedIcon={<OnSymbol />}
                        className="me-1 mb-sm-8 mb-2"
                        onColor="#626ed4"
                      />
                    </>
                  )}
                />
              </div>
            </div> */}
           
          </Col>
         
       
        </Row>
      </FormProvider>
    </PageModal>
  )
}

NoteModal.propTypes = {
  onCloseClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  show: PropTypes.any,
}

export default NoteModal
