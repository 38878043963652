import {
  GET_CLASSES,
  GET_CLASSES_FAIL,
  GET_CLASSES_SUCCESS,
  DELETE_CLASS,
  DELETE_CLASS_FAIL,
  DELETE_CLASS_SUCCESS,
  ADD_NEW_CLASS,
  ADD_CLASS_SUCCESS,
  ADD_CLASS_FAIL,
  GET_CLASS_DETAIL,
  GET_CLASS_DETAIL_SUCCESS,
  GET_CLASS_DETAIL_FAIL,
  UPDATE_CLASS,
  UPDATE_CLASS_SUCCESS,
  UPDATE_CLASS_FAIL,
  GET_CLASS_TYPES,
  GET_CLASS_TYPES_SUCCESS,
  GET_CLASS_TYPES_FAIL,
  RESET_CLASS_SUCCESS,
  RESET_CLASS,
  ADD_CLASS_NOTES,
  ADD_CLASS_NOTES_SUCCESS,
  ADD_CLASS_NOTES_FAIL,
  DELETE_CLASS_NOTES,
  DELETE_CLASS_NOTES_SUCCESS,
  DELETE_CLASS_NOTES_FAIL,
  GET_CLASS_HAPPENING_TYPES,
  GET_CLASS_HAPPENING_TYPES_SUCCESS,
  GET_CLASS_HAPPENING_TYPES_FAIL,
  GET_CLASS_HAPPENING,
  GET_CLASS_HAPPENING_SUCCESS,
  GET_CLASS_HAPPENING_FAIL,
  ADD_CLASS_HAPPENING,
  ADD_CLASS_HAPPENING_SUCCESS,
  ADD_CLASS_HAPPENING_FAIL,
  DELETE_CLASS_HAPPENING,
  DELETE_CLASS_HAPPENING_SUCCESS,
  DELETE_CLASS_HAPPENING_FAIL,
} from "./actionTypes"

export const getClasses = () => ({
  type: GET_CLASSES,
})

export const getClassesSuccess = classes => ({
  type: GET_CLASSES_SUCCESS,
  payload: classes,
})

export const getClassesFail = error => ({
  type: GET_CLASSES_FAIL,
  payload: error,
})

export const deleteClass = cls => ({
  type: DELETE_CLASS,
  payload: cls,
})

export const deleteClassSuccess = cls => ({
  type: DELETE_CLASS_SUCCESS,
  payload: cls,
})

export const deleteClassFail = error => ({
  type: DELETE_CLASS_FAIL,
  payload: error,
})
export const addNewClass = cls => ({
  type: ADD_NEW_CLASS,
  payload: cls,
})

export const addClassSuccess = cls => ({
  type: ADD_CLASS_SUCCESS,
  payload: cls,
})

export const addClassFail = error => ({
  type: ADD_CLASS_FAIL,
  payload: error,
})

export const getClassDetail = classId => ({
  type: GET_CLASS_DETAIL,
  classId,
})

export const getClassDetailSuccess = cls => ({
  type: GET_CLASS_DETAIL_SUCCESS,
  payload: cls,
})

export const getClassDetailFail = error => ({
  type: GET_CLASS_DETAIL_FAIL,
  payload: error,
})

export const updateClass = cls => ({
  type: UPDATE_CLASS,
  payload: cls,
})

export const updateClassSuccess = cls => ({
  type: UPDATE_CLASS_SUCCESS,
  payload: cls,
})

export const updateClassFail = error => ({
  type: UPDATE_CLASS_FAIL,
  payload: error,
})

export const getClassTypes = () => ({
  type: GET_CLASS_TYPES,
})

export const getClassTypesSuccess = classTypes => ({
  type: GET_CLASS_TYPES_SUCCESS,
  payload: classTypes,
})

export const getClassTypesFail = error => ({
  type: GET_CLASS_TYPES_FAIL,
  payload: error,
})

export const resetClass = () => ({
  type: RESET_CLASS,
})

export const resetClassSuccess = class1 => ({
  type: RESET_CLASS_SUCCESS,
  payload: class1,
})

export const addClassNote = note => ({
  type: ADD_CLASS_NOTES,
  payload: note,
})

export const addClassNoteSuccess = note => ({
  type: ADD_CLASS_NOTES_SUCCESS,
  payload: note,
})

export const addClassNoteFail = error => ({
  type: ADD_CLASS_NOTES_FAIL,
  payload: error,
})

export const deleteClassNote = classNoteId => ({
  type: DELETE_CLASS_NOTES,
  payload: classNoteId,
})

export const deleteClassNoteSuccess = classNoteId => ({
  type: DELETE_CLASS_NOTES_SUCCESS,
  payload: classNoteId,
})

export const deleteClassNoteFail = error => ({
  type: DELETE_CLASS_NOTES_FAIL,
  payload: error,
})

export const getClassHappeningTypes = () => ({
  type: GET_CLASS_HAPPENING_TYPES,
})

export const getClassHappeningTypesSuccess = happeningTypes => ({
  type: GET_CLASS_HAPPENING_TYPES_SUCCESS,
  payload: happeningTypes,
})

export const getClassHappeningTypesFail = error => ({
  type: GET_CLASS_HAPPENING_TYPES_FAIL,
  payload: error,
})

export const getClassHappenings = (classID, filterDate) => ({
  type: GET_CLASS_HAPPENING,
  payload: { classID, filterDate },
})

export const getClassHappeningsSuccess = classHappenings => ({
  type: GET_CLASS_HAPPENING_SUCCESS,
  payload: classHappenings,
})

export const getClassHappeningsFail = error => ({
  type: GET_CLASS_HAPPENING_FAIL,
  payload: error,
})

export const addClassHappening = classHappening => ({
  type: ADD_CLASS_HAPPENING,
  payload: classHappening,
})

export const addClassHappeningSuccess = classHappening => ({
  type: ADD_CLASS_HAPPENING_SUCCESS,
  payload: classHappening,
})

export const addClassHappeningFail = error => ({
  type: ADD_CLASS_HAPPENING_FAIL,
  payload: error,
})

export const deleteClassHappening = classHappening => ({
  type: DELETE_CLASS_HAPPENING,
  payload: classHappening,
})

export const deleteClassHappeningSuccess = classHappeningID => ({
  type: DELETE_CLASS_HAPPENING_SUCCESS,
  payload: classHappeningID,
})

export const deleteClassHappeningFail = error => ({
  type: DELETE_CLASS_HAPPENING_FAIL,
  payload: error,
})
