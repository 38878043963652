import React, { useState, useEffect } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import {
  Button,
  Card,
  CardBody,
  CardText,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Alert,
} from "reactstrap"

import BasicInfoTab from "./tabs/BasicInfoTab"
import { getClassDetail as onGetClassDetail } from "store/actions"
import { isEmpty } from "lodash"
import EmptyContainer from "components/Common/EmptyContainer"
import { useDeepCompareEffect } from "hooks"
import classnames from "classnames"
import Document from "components/Common/Document"
import { EnumObjectTypes } from "helpers/enum_helper"
import NotesTab from "./tabs/NotesTab"
import HappeningTab from "./tabs/HappeningTab"

const ClassAddEdit = () => {
  const navigate = useNavigate()
  const routeParams = useParams()
  const dispatch = useDispatch()
  const [header, setHeader] = useState("")
  const [tabValue, setTabValue] = useState("1")
  const [objCurr, setObjCurr] = useState(null)

  useDeepCompareEffect(() => {
    const { id } = routeParams

    if (id === "new") {
      setHeader("Add Class")
    } else {
      setHeader("Edit Class")
      dispatch(onGetClassDetail(id))
    }
  }, [dispatch, routeParams])

  //////////////////Class1 STATE/////////////////////
  const data = useSelector(state => state.class1)
  //////////////////////////////////////

  const toggleTab = tab => {
    if (tabValue !== tab) {
      setTabValue(tab)
    }
  }

  const NavItemTab = (navTitle, navValue) => {
    return (
      <NavItem>
        <NavLink
          style={{ cursor: "pointer" }}
          className={classnames({
            active: tabValue === navValue,
          })}
          onClick={() => {
            toggleTab(navValue)
          }}
        >
          {navTitle}
        </NavLink>
      </NavItem>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Class" breadcrumbItem={header} />
          {routeParams.id !== "new" && isEmpty(data.class1) ? (
            <EmptyContainer
              backURL="/classes"
              message="There is no such class1!"
              linkText="Go to Classes Page"
            />
          ) : (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <CardTitle>Basic Information</CardTitle>
                    <p className="card-title-desc mb-4">
                      Fill all information below
                    </p>
                    {routeParams.id !== "new" &&
                    data.class1.ClassTypeID == 1 ? (
                      <Nav tabs className="nav-tabs-custom nav-justified">
                        {NavItemTab("Basic Info", "1")}
                        {NavItemTab("Documents", "2")}
                        {NavItemTab("Notebook", "3")}
                        {NavItemTab("Happenings", "4")}
                      </Nav>
                    ) : (
                      ""
                    )}
                    <TabContent activeTab={tabValue} className="p-3 text-muted">
                      <TabPane tabId="1">
                        <Row>
                          <Col sm="12">
                            <BasicInfoTab />
                          </Col>
                        </Row>
                      </TabPane>
                      {routeParams.id !== "new" ? (
                        <>
                          <TabPane tabId="2">
                            <Row>
                              <Col sm="12">
                                <Document
                                  objectID={data.class1.ClassID}
                                  objectTypeID={EnumObjectTypes.Class}
                                />
                              </Col>
                            </Row>
                          </TabPane>
                          <TabPane tabId="3">
                            <Row>
                              <Col sm="12">
                                <NotesTab />
                              </Col>
                            </Row>
                          </TabPane>
                          <TabPane tabId="4">
                            <Row>
                              <Col sm="12">
                                <HappeningTab />
                              </Col>
                            </Row>
                          </TabPane>
                        </>
                      ) : (
                        ""
                      )}
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ClassAddEdit
