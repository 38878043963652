import {
  GET_LESSONS,
  GET_LESSONS_FAIL,
  GET_LESSONS_SUCCESS,
  DELETE_LESSON,
  DELETE_LESSON_FAIL,
  DELETE_LESSON_SUCCESS,
  ADD_LESSON,
  ADD_LESSON_SUCCESS,
  ADD_LESSON_FAIL,
  GET_LESSON_DETAIL,
  GET_LESSON_DETAIL_SUCCESS,
  GET_LESSON_DETAIL_FAIL,
  UPDATE_LESSON,
  UPDATE_LESSON_SUCCESS,
  UPDATE_LESSON_FAIL,
  RESET_LESSON_SUCCESS,
  RESET_LESSON,
  ADD_LESSON_SCHEDULE,
  ADD_LESSON_SCHEDULE_SUCCESS,
  ADD_LESSON_SCHEDULE_FAIL,
  DELETE_LESSON_SCHEDULE,
  DELETE_LESSON_SCHEDULE_SUCCESS,
  DELETE_LESSON_SCHEDULE_FAIL,
  GET_LESSONSCHEDULES_BY_LESSON,
  GET_LESSONSCHEDULES_BY_LESSON_SUCCESS,
  GET_LESSONSCHEDULES_BY_LESSON_FAIL,
  UPDATE_LESSONSCHEDULE_DETAIL,
  UPDATE_LESSONSCHEDULE_DETAIL_SUCCESS,
  UPDATE_LESSONSCHEDULE_DETAIL_FAIL,
  GET_LESSONSCHEDULE_DETAIL_BYLSD,
  GET_LESSONSCHEDULE_DETAIL_BYLSD_SUCCESS,
  GET_LESSONSCHEDULE_DETAIL_BYLSD_FAIL,
  DELETE_LESSON_SCHEDULE_DETAIL,
  DELETE_LESSON_SCHEDULE_DETAIL_SUCCESS,
  DELETE_LESSON_SCHEDULE_DETAIL_FAIL
  } from "./actionTypes"


// Add-ons


export const getLessons = () => ({
  type: GET_LESSONS,
})

export const getLessonsSuccess = lessons => ({
  type: GET_LESSONS_SUCCESS,
  payload: lessons,
})

export const getLessonsFail = error => ({
  type: GET_LESSONS_FAIL,
  payload: error,
})

export const deleteLesson = lessonID => ({
  type: DELETE_LESSON,
  payload: lessonID,
})

export const deleteLessonSuccess = lesson => ({
  type: DELETE_LESSON_SUCCESS,
  payload: lesson,
})

export const deleteLessonFail = error => ({
  type: DELETE_LESSON_FAIL,
  payload: error,
})


export const addLesson = lesson => ({
  type: ADD_LESSON,
  payload: lesson,
})

export const addLessonSuccess = lesson => ({
  type: ADD_LESSON_SUCCESS,
  payload: lesson,
})

export const addLessonFail = error => ({
  type: ADD_LESSON_FAIL,
  payload: error,
})


export const getLessonDetail = RowID => ({
  type: GET_LESSON_DETAIL,
  RowID,
})

export const getLessonDetailSuccess = lesson => ({
  type: GET_LESSON_DETAIL_SUCCESS,
  payload: lesson,
})

export const getLessonDetailFail = error => ({
  type: GET_LESSON_DETAIL_FAIL,
  payload: error,
})

export const updateLesson = lesson => ({
  type: UPDATE_LESSON,
  payload: lesson,
})

export const updateLessonSuccess = lesson => ({
  type: UPDATE_LESSON_SUCCESS,
  payload: lesson,
})

export const updateLessonFail = error => ({
  type: UPDATE_LESSON_FAIL,
  payload: error,
})


export const resetLesson= () => ({
  type: RESET_LESSON
 
})

export const resetLessonSuccess = lesson => ({
  type: RESET_LESSON_SUCCESS,
  payload: lesson,
})

export const addLessonSchedule = lessonschedule => ({
  type: ADD_LESSON_SCHEDULE,
  payload: lessonschedule,
})

export const addLessonScheduleSuccess = lessonschedule => ({
  type: ADD_LESSON_SCHEDULE_SUCCESS,
  payload: lessonschedule,
})

export const addLessonScheduleFail = error => ({
  type: ADD_LESSON_SCHEDULE_FAIL,
  payload: error,
})

//  Lesson schedules

export const getLessonSchedulesByLesson = (RowID) => ({
  type: GET_LESSONSCHEDULES_BY_LESSON,
  RowID
})

export const getLessonSchedulesByLessonSuccess = lessons => ({
  type: GET_LESSONSCHEDULES_BY_LESSON_SUCCESS,
  payload: lessons,
})

export const getLessonSchedulesByLessonFail = error => ({
  type: GET_LESSONSCHEDULES_BY_LESSON_FAIL,
  payload: error,
})

export const updateLessonScheduleDetail = lessonScheduleDetail => ({
  type: UPDATE_LESSONSCHEDULE_DETAIL,
  payload: lessonScheduleDetail
  
})

export const updateLessonScheduleDetailSuccess = lessonScheduleDetail => ({
  type: UPDATE_LESSONSCHEDULE_DETAIL_SUCCESS,
  payload: lessonScheduleDetail,
})

export const updateLessonScheduleDetailFail = error => ({
  type: UPDATE_LESSONSCHEDULE_DETAIL_FAIL,
  payload: error,
})


export const getLessonScheduleDetailbyLSD = lessonScheduleDetail => ({
  type: GET_LESSONSCHEDULE_DETAIL_BYLSD,
  payload: lessonScheduleDetail,
})

export const getLessonScheduleDetailbyLSDSuccess = lessonScheduleDetail => ({
  type: GET_LESSONSCHEDULE_DETAIL_BYLSD_SUCCESS,
  payload: lessonScheduleDetail,
})

export const getLessonScheduleDetailbyLSDFail = error => ({
  type: GET_LESSONSCHEDULE_DETAIL_BYLSD_FAIL,
  payload: error,
})

export const deleteLessonSchedule = lessonScheduleID => ({
  type: DELETE_LESSON_SCHEDULE,
  payload: lessonScheduleID,
})

export const deleteLessonScheduleSuccess = lessonScheduleID => ({
  type: DELETE_LESSON_SCHEDULE_SUCCESS,
  payload: lessonScheduleID,
})

export const deleteLessonScheduleFail = error => ({
  type: DELETE_LESSON_SCHEDULE_FAIL,
  payload: error,
})



export const deleteLessonScheduleDetail = lessonScheduleDetailID => ({
  type: DELETE_LESSON_SCHEDULE_DETAIL,
  payload: lessonScheduleDetailID,
})

export const deleteLessonScheduleDetailSuccess = lessonScheduleDetailID => ({
  type: DELETE_LESSON_SCHEDULE_DETAIL_SUCCESS,
  payload: lessonScheduleDetailID,
})

export const deleteLessonScheduleDetailFail = error => ({
  type: DELETE_LESSON_SCHEDULE_DETAIL_FAIL,
  payload: error,
})
