import React, { useState, useEffect } from "react"

//controls
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Row,
  FormFeedback,
  InputGroup,
} from "reactstrap"
import Flatpickr from "react-flatpickr"
import Switch from "react-switch"

//react form
//react form
import {
  FormProvider,
  useForm,
  Controller,
  useFieldArray,
} from "react-hook-form"
import Select from "react-select"
import { createSelector } from "reselect"

//navigation
import { Link, useNavigate, useParams } from "react-router-dom"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

//custom hooks
import { useDeepCompareEffect } from "hooks"

import { isEmpty } from "lodash"
//Date filter
import Moment from "moment"

import {
  addNote as onAddNote,
  updateNote as onUpdateNote,
  getPrograms as onGetPrograms,
  resetNote as onResetNote,
} from "store/actions"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"
import Spinners from "components/Common/Spinner"
import PageModal from "components/Common/PageModal"
const formdefault = {
  NoteID: 0,
  Title: "",
}
function BasicInfoTab({ note }) {
  const navigate = useNavigate()
  const routeParams = useParams()
  const dispatch = useDispatch()
  // const [isLoading, setLoading] = useState(loading)
  const { programs } = useSelector(state => state.program)
  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    NoteID: yup.number(),
    Title: yup.string().required("Required"),
    Programs: yup.object().required("Required")
  
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: formdefault,
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, setValue, getValues, trigger } = methods
  const { errors, isValid } = formState

  useDeepCompareEffect(() => {
    function updateCurriculumState() {
      dispatch(onGetPrograms())
    }

    updateCurriculumState()
  }, [dispatch])

  useEffect(() => {
    const { id } = routeParams
    if (id === "new") {
      reset()
    } else {
      //reset form with server data
      const response = {
        ...note,
        Programs: {
          ProgramID: Number(note?.ProgramID),
          Name: note?.ProgramName,
        },
      }
      reset(response)
    }

    return () => {
      //  dispatch(onResetNote())
    }
  }, [dispatch, note])

  const onCancelClick = () => {
    navigate("/learning/notes")
  }

  const handleSave = () => {
    trigger() //for form validation
    if (isValid) {
      const { id } = routeParams

      const newNote = {
        ...getValues(),
        id: id,
        NoteID: note?.NoteID,
      }
      if (id === "new") {
        dispatch(onAddNote(newNote))
      } else {
        dispatch(onUpdateNote(newNote))
      }

      navigate("/learning/notes")
    }
  }

  return (
    <FormProvider {...methods}>
      <Row>
        <Col lg={6}>
          <div className="mb-3">
            <Label>Title</Label>
            <Controller
              name="Title"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    id="Title"
                    type="text"
                    required
                    invalid={!!errors.Title}
                  />
                  {errors?.Title?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.Title?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
          
          <div className="mb-3">
            <Label>Program</Label>
            <Controller
              name="Programs"
              control={control}
              render={({ field }) => (
                <>
                  <Select
                    {...field}
                    id="Programs"
                    options={programs}
                    getOptionLabel={option => option.Name}
                    getOptionValue={option => option.ProgramID}
                    required
                    aria-invalid={!!errors.Programs}
                    classNamePrefix="select2-selection"
                  />
                  {errors?.Programs?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.Programs?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
        </Col>
      </Row>
      <div className="d-flex flex-wrap gap-2">
        <Button color="primary" className="btn" onClick={handleSave}>
          {routeParams.id === "new" ? "Save" : "Update"}
        </Button>
        <Button
          type="submit"
          color="secondary"
          onClick={onCancelClick}
          className=""
        >
          Cancel
        </Button>
      </div>
    </FormProvider>
  )
}
export default BasicInfoTab
