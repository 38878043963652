import React, { useState, useEffect } from "react"

//controls
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Row,
  FormFeedback,
  InputGroup,
} from "reactstrap"
import Flatpickr from "react-flatpickr"
import Switch from "react-switch"

//react form
import { FormProvider, useForm, Controller } from "react-hook-form"
import Select from "react-select"
import { createSelector } from "reselect"

//navigation
import { Link, useNavigate, useParams } from "react-router-dom"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

//custom hooks
import { useDeepCompareEffect } from "hooks"

//Date filter
import Moment from "moment"

import {
  //addNewAlbum as onAddNewAlbum,
  //updateAlbum as onUpdateAlbum,
  // getClasses as onGetClasses,
  addCurriculum as onAddCurriculum,
  updateCurriculum as onUpdateCurriculum,
  getPrograms as onGetPrograms,
  resetCurriculum as onResetCurriculum,
  // resetAlbum as onResetAlbum,
} from "store/actions"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"
import Spinners from "components/Common/Spinner"
const formdefault = {
  CurriculumID: 0,
  Name: "",
  IsShared: false,
}
function BasicInfoTab({ curriculum }) {
  const navigate = useNavigate()
  const routeParams = useParams()
  const dispatch = useDispatch()
  // const [isLoading, setLoading] = useState(loading)
  const { programs } = useSelector(state => state.program)
  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    CurriculumID: yup.number(),
    Name: yup.string().required("Required"),
    Programs: yup.object().required("Required"),
    IsShared: yup.bool(),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: formdefault,
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, setValue, getValues, trigger } = methods
  const { errors, isValid } = formState

  useDeepCompareEffect(() => {
    function updateCurriculumState() {
      dispatch(onGetPrograms())
    }

    updateCurriculumState()
  }, [dispatch])

  useEffect(() => {
    const { id } = routeParams
    if (id === "new") {
      reset()
    } else {
      //reset form with server data
      const response = {
        ...curriculum,
        Programs: {
          ProgramID: Number(curriculum?.ProgramID),
          Name: curriculum?.ProgramName,
        },
      }
      reset(response)
    }

    return () => {
      dispatch(onResetCurriculum())
    }
  }, [dispatch, curriculum])

  const onCancelClick = () => {
    navigate("/learning/curriculums")
  }

  const handleSave = () => {
    trigger() //for form validation
    if (isValid) {
      const { id } = routeParams

      const newCurriculum = {
        ...getValues(),
        id: id,
        CurriculumID: curriculum?.CurriculumID,
      }
      if (id === "new") {
        dispatch(onAddCurriculum(newCurriculum))
      } else {
        dispatch(onUpdateCurriculum(newCurriculum))
      }

      navigate("/learning/curriculums")
    }
  }

  return (
    <FormProvider {...methods}>
      <Row>
        <Col sm="6">
          {/* <div className="mb-3">
            <Label>Title</Label>
            <Controller
              name="Title"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    id="Title"
                    type="text"
                    required
                    invalid={!!errors.Title}
                  />
                  {errors?.Title?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.Title?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
          <div className="mb-3">
            <Label>Active</Label>
            <div>
              <Controller
                name="IsActive"
                control={control}
                render={({ field }) => (
                  <>
                    <Switch
                      {...field}
                      id="IsActive"
                      checked={field.value}
                      uncheckedIcon={<Offsymbol />}
                      checkedIcon={<OnSymbol />}
                      className="me-1 mb-sm-8 mb-2"
                      onColor="#626ed4"
                    />
                  </>
                )}
              />
            </div>
          </div> */}

          <div className="mb-3">
            <Label>Name</Label>
            <Controller
              name="Name"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    id="Name"
                    type="text"
                    required
                    invalid={!!errors.Name}
                  />
                  {errors?.Name?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.Name?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
          <div className="mb-3">
            <Label>Program</Label>
            <Controller
              name="Programs"
              control={control}
              render={({ field }) => (
                <>
                  <Select
                    {...field}
                    id="Programs"
                    options={programs}
                    getOptionLabel={option => option.Name}
                    getOptionValue={option => option.ProgramID}
                    required
                    aria-invalid={!!errors.Programs}
                    classNamePrefix="select2-selection"
                  />
                  {errors?.Programs?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.Programs?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
          <div className="mb-3">
            <Label>Shared with Parent</Label>
            <div>
              <Controller
                name="IsShared"
                control={control}
                render={({ field }) => (
                  <>
                    <Switch
                      {...field}
                      id="IsShared"
                      checked={field.value}
                      uncheckedIcon={<Offsymbol />}
                      checkedIcon={<OnSymbol />}
                      className="me-1 mb-sm-8 mb-2"
                      onColor="#626ed4"
                    />
                  </>
                )}
              />
            </div>
          </div>
        </Col>
      </Row>
      <div className="d-flex flex-wrap gap-2">
        <Button color="primary" className="btn" onClick={handleSave}>
          {routeParams.id === "new" ? "Save" : "Update"}
        </Button>
        <Button
          type="submit"
          color="secondary"
          onClick={onCancelClick}
          className=""
        >
          Cancel
        </Button>
      </div>
    </FormProvider>
  )
}
export default BasicInfoTab
