/**
 * Add CURRICULUMS
 */

export const GET_CURRICULUMS = "GET_CURRICULUMS"
export const GET_CURRICULUMS_SUCCESS = "GET_CURRICULUMS_SUCCESS"
export const GET_CURRICULUMS_FAIL = "GET_CURRICULUMS_FAIL"

export const DELETE_CURRICULUM = "DELETE_CURRICULUM"
export const DELETE_CURRICULUM_FAIL = "DELETE_CURRICULUM_FAIL"
export const DELETE_CURRICULUM_SUCCESS = "DELETE_CURRICULUM_SUCCESS"

export const ADD_CURRICULUM = "ADD_CURRICULUM"
export const ADD_CURRICULUM_SUCCESS = "ADD_CURRICULUM_SUCCESS"
export const ADD_CURRICULUM_FAIL = "ADD_CURRICULUM_FAIL"

export const GET_CURRICULUM_DETAIL = "GET_CURRICULUM_DETAIL"
export const GET_CURRICULUM_DETAIL_SUCCESS = "GET_CURRICULUM_DETAIL_SUCCESS"
export const GET_CURRICULUM_DETAIL_FAIL = "GET_CURRICULUM_DETAIL_FAIL"

export const UPDATE_CURRICULUM = "UPDATE_CURRICULUM"
export const UPDATE_CURRICULUM_SUCCESS = "UPDATE_CURRICULUM_SUCCESS"
export const UPDATE_CURRICULUM_FAIL = "UPDATE_CURRICULUM_FAIL"
/**
 * Reset CURRICULUM
 */
export const RESET_CURRICULUM = "RESET_CURRICULUM"
export const RESET_CURRICULUM_SUCCESS = "RESET_CURRICULUM_SUCCESS"

export const GET_NOTECATEGORIES = "GET_NOTECATEGORIES"
export const GET_NOTECATEGORIES_SUCCESS = "GET_NOTECATEGORIES_SUCCESS"
export const GET_NOTECATEGORIES_FAIL = "GET_NOTECATEGORIES_FAIL"

// ADD Curriculum Schedules


export const ADD_CURRICULUM_SCHEDULE = "ADD_CURRICULUM_SCHEDULE"
export const ADD_CURRICULUM_SCHEDULE_SUCCESS = "ADD_CURRICULUM_SCHEDULE_SUCCESS"
export const ADD_CURRICULUM_SCHEDULE_FAIL = "ADD_CURRICULUM_SCHEDULE_FAIL"

export const DELETE_CURRICULUM_SCHEDULE = "DELETE_CURRICULUM_SCHEDULE"
export const DELETE_CURRICULUM_SCHEDULE_SUCCESS = "DELETE_CURRICULUM_SCHEDULE_SUCCESS"
export const DELETE_CURRICULUM_SCHEDULE_FAIL = "DELETE_CURRICULUM_SCHEDULE_FAIL"

//getCurriculumSchedulesByCurriculum
export const GET_CURRICULUMSHEDULES_BY_CURRICULUM = "GET_CURRICULUMSHEDULES_BY_CURRICULUM"
export const GET_CURRICULUMSHEDULES_BY_CURRICULUM_SUCCESS = "GET_CURRICULUMSHEDULES_BY_CURRICULUM_SUCCESS"
export const GET_CURRICULUMSHEDULES_BY_CURRICULUM_FAIL = "GET_CURRICULUMSHEDULES_BY_CURRICULUM_FAIL"

// Update CurriculumScheduleDetails Details
export const UPDATE_CURRICULUMSHEDULE_DETAIL = "UPDATE_CURRICULUMSHEDULE_DETAIL"
export const UPDATE_CURRICULUMSHEDULE_DETAIL_SUCCESS = "UPDATE_CURRICULUMSHEDULE_DETAIL_SUCCESS"
export const UPDATE_CURRICULUMSHEDULE_DETAIL_FAIL = "UPDATE_CURRICULUMSHEDULE_DETAIL_FAIL"


export const GET_CURRICULUMSHEDULE_DETAIL_BYCSD = "GET_CURRICULUMSHEDULE_DETAIL_BYCSD"
export const GET_CURRICULUMSHEDULE_DETAIL_BYCSD_SUCCESS = "GET_CURRICULUMSHEDULE_DETAIL_BYCSD_SUCCESS"
export const GET_CURRICULUMSHEDULE_DETAIL_BYCSD_FAIL = "GET_CURRICULUMSHEDULE_DETAIL_BYCSD_FAIL"

export const DELETE_CURRICULUM_SCHEDULE_DETAIL = "DELETE_CURRICULUM_SCHEDULE_DETAIL"
export const DELETE_CURRICULUM_SCHEDULE_DETAIL_SUCCESS = "DELETE_CURRICULUM_SCHEDULE_DETAIL_SUCCESS"
export const DELETE_CURRICULUM_SCHEDULE_DETAIL_FAIL = "DELETE_CURRICULUM_SCHEDULE_DETAIL_FAIL"


