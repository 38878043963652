import {
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAIL,
  UPDATE_IMPERSONATE_USER_SUCCESS,
  UPDATE_IMPERSONATE_USER_FAIL,
  DELETE_IMPERSONATE_USER_SUCCESS,
  DELETE_IMPERSONATE_USER_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  userprofile: {},
  error: {},
  loading: false,
}

const User = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        userprofile: action.payload,
        loading: true,
      }
    case GET_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_IMPERSONATE_USER_SUCCESS:
      return {
        ...state,
        userprofile: action.payload,
        loading: true,
      }
    case UPDATE_IMPERSONATE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_IMPERSONATE_USER_SUCCESS:
      return {
        ...state,
        loading: true,
      }
    case DELETE_IMPERSONATE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default User
