import { call, put, takeEvery } from "redux-saga/effects"

// note Redux States
import {
  GET_NOTES,
  DELETE_NOTE,
  ADD_NOTE,
  GET_NOTE_DETAIL,
  UPDATE_NOTE,
  GET_NOTECATEGORIES,
  RESET_NOTE,
  ADD_NOTE_SCHEDULE,
  GET_NOTESCHEDULES_BY_NOTE,
  UPDATE_NOTESCHEDULE_DETAIL,
  GET_NOTESCHEDULE_DETAIL_BYNSD,
  DELETE_NOTE_SCHEDULE,
  DELETE_NOTE_SCHEDULE_DETAIL,
} from "./actionTypes"
import {
  getAllNoteSuccess,
  getAllNoteFail,
  deleteNoteSuccess,
  deleteNoteFail,
  addNoteSuccess,
  addNoteFail,
  getNoteDetailSuccess,
  getNoteDetailFail,
  updateNoteSuccess,
  updateNoteFail,
  resetNoteSuccess,
  getNoteCategoriesSuccess,
  getNoteCategoriesFail,
  addNoteScheduleSuccess,
  addNoteScheduleFail,
  getNoteSchedulesByNoteSuccess,
  getNoteSchedulesByNoteFail,
  updateNoteScheduleDetailSuccess,
  updateNoteScheduleDetailFail,
  getNoteScheduleDetailbyNSDSuccess,
  getNoteScheduleDetailbyNSDFail,
  deleteNoteScheduleSuccess,
  deleteNoteScheduleFail,
  deleteNoteScheduleDetailSuccess,
  deleteNoteScheduleDetailFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getAllNote,
  deleteNote,
  addNewNote,
  getNoteDetail,
  updateNote,
  addNoteSchedule,
  deleteNoteSchedule,
  getNoteSchedulesByNote,
  updateNoteScheduleDetail,
  getNoteScheduleDetailbyNSD,
  deleteNoteScheduleDetail,
} from "helpers/backend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function* fetchNotes() {
  try {
    const response = yield call(getAllNote)
    yield put(getAllNoteSuccess(response))
  } catch (error) {
    yield put(getAllNoteFail(error))
  }
}

function* onDeleteNote({ payload: NoteID }) {
  try {
    const response = yield call(deleteNote, NoteID)
    yield put(deleteNoteSuccess(response))
    toast.success("Note deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteNoteFail(error))
    toast.error("Note deletion failed", { autoClose: 2000 })
  }
}

function* onAddNote({ payload: note }) {
  try {
    const noteNew = {
      ...note,
      ProgramID: note.Programs?.ProgramID ? note.Programs?.ProgramID : 0,
    }
    const response = yield call(addNewNote, noteNew)
    yield put(addNoteSuccess(response[0]))
    toast.success("Note added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addNoteFail(error))
    toast.error("Note addition failed", { autoClose: 2000 })
  }
}

function* fetchNoteDetail({ RowID }) {
  try {
    const response = yield call(getNoteDetail, RowID)
    let result = response[0]
    result = {
      ...result,
    }
    yield put(getNoteDetailSuccess(result))
  } catch (error) {
    yield put(getNoteDetailFail(error))
  }
}

function* onUpdateNote({ payload: note }) {
  try {
    const noteNew = {
      ...note,
      ProgramID: note.Programs?.ProgramID ? note.Programs?.ProgramID : 0,
    }
    const response = yield call(updateNote, noteNew)
    let result = response[0]

    yield put(updateNoteSuccess(result))
    toast.success("Note updated successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateNoteFail(error))
    toast.error("Note updation failed", { autoClose: 2000 })
  }
}

function* onResetNote() {
  try {
    yield put(resetNoteSuccess({}))
  } catch (error) {}
}

function* onAddNoteSchedule({ payload: noteschedule }) {
  try {
    const notescheduleNew = {
      ...noteschedule,
      NoteCategoryID: noteschedule.NoteCategories?.NoteCategoryID
        ? noteschedule.NoteCategories?.NoteCategoryID
        : 0,
    }

    const response = yield call(addNoteSchedule, notescheduleNew)

    yield put(addNoteScheduleSuccess(response[0]))
    toast.success("Note Schedule added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addNoteScheduleFail(error))
    toast.error("Note Schedule addition failed", { autoClose: 2000 })
  }
}
function* fetchNoteSchedulesByNote({ RowID }) {
  try {
    const response = yield call(getNoteSchedulesByNote, RowID)
    yield put(getNoteSchedulesByNoteSuccess(response))
  } catch (error) {
    yield put(getNoteSchedulesByNoteFail(error))
  }
}
function* onUpdateNoteScheduleDetail({ payload: noteScheduleDetail }) {
  try {
    const noteScheduleDetailNew = {
      ...noteScheduleDetail,
      NoteCategoryID: noteScheduleDetail.NoteCategories?.NoteCategoryID
        ? noteScheduleDetail.NoteCategories?.NoteCategoryID
        : 0,
    }

    const response = yield call(updateNoteScheduleDetail, noteScheduleDetailNew)
    let result = response[0]
    yield put(updateNoteScheduleDetailSuccess(result))
    toast.success("Note Schedule detail updated successfully", {
      autoClose: 2000,
    })
  } catch (error) {
    yield put(updateNoteScheduleDetailFail(error))
    toast.error("Note Schedule detail updation failed", {
      autoClose: 2000,
    })
  }
}

function* fetchNoteScheduleDetailsByNSD({ payload: noteScheduleDetail }) {
  try {
    const response = yield call(getNoteScheduleDetailbyNSD, noteScheduleDetail)
    yield put(getNoteScheduleDetailbyNSDSuccess(response))
  } catch (error) {
    yield put(getNoteScheduleDetailbyNSDFail(error))
  }
}

function* onDeleteNoteSchedule({ payload: NoteScheduleID }) {
  try {
    const response = yield call(deleteNoteSchedule, NoteScheduleID)
    yield put(deleteNoteScheduleSuccess(response))
    toast.success("Note schedule deleted successfully", {
      autoClose: 2000,
    })
  } catch (error) {
    yield put(deleteNoteScheduleFail(error))
    toast.error("Note schedule deletion failed", { autoClose: 2000 })
  }
}

function* onDeleteNoteScheduleDetail({ payload: NoteScheduleDetailID }) {
  try {
    const response = yield call(deleteNoteScheduleDetail, NoteScheduleDetailID)
    yield put(deleteNoteScheduleDetailSuccess(response))
    toast.success("Note schedule detail deleted successfully", {
      autoClose: 2000,
    })
  } catch (error) {
    yield put(deleteNoteScheduleDetailFail(error))
    toast.error("Note schedule detail deletion failed", {
      autoClose: 2000,
    })
  }
}

function* noteSaga() {
  yield takeEvery(GET_NOTES, fetchNotes)
  yield takeEvery(DELETE_NOTE, onDeleteNote)
  yield takeEvery(ADD_NOTE, onAddNote)
  yield takeEvery(GET_NOTE_DETAIL, fetchNoteDetail)
  yield takeEvery(UPDATE_NOTE, onUpdateNote)
  yield takeEvery(RESET_NOTE, onResetNote)
  yield takeEvery(ADD_NOTE_SCHEDULE, onAddNoteSchedule)
  yield takeEvery(GET_NOTESCHEDULES_BY_NOTE, fetchNoteSchedulesByNote)
  yield takeEvery(UPDATE_NOTESCHEDULE_DETAIL, onUpdateNoteScheduleDetail)
  yield takeEvery(GET_NOTESCHEDULE_DETAIL_BYNSD, fetchNoteScheduleDetailsByNSD)
  yield takeEvery(DELETE_NOTE_SCHEDULE, onDeleteNoteSchedule)
  yield takeEvery(DELETE_NOTE_SCHEDULE_DETAIL, onDeleteNoteScheduleDetail)
}

export default noteSaga
