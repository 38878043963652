/*Get CLASS */
export const GET_CLASSES = "GET_CLASSES"
export const GET_CLASSES_SUCCESS = "GET_CLASSES_SUCCESS"
export const GET_CLASSES_FAIL = "GET_CLASSES_FAIL"

/**
 * Delete CLASS
 */
export const DELETE_CLASS = "DELETE_CLASS"
export const DELETE_CLASS_SUCCESS = "DELETE_CLASS_SUCCESS"
export const DELETE_CLASS_FAIL = "DELETE_CLASS_FAIL"
/**
 * Add CLASS
 */
export const ADD_NEW_CLASS = "ADD_NEW_CLASS"
export const ADD_CLASS_SUCCESS = "ADD_CLASS_SUCCESS"
export const ADD_CLASS_FAIL = "ADD_CLASS_FAIL"

/**
 * Get CLASS DETAILS
 */
export const GET_CLASS_DETAIL = "GET_CLASS_DETAIL"
export const GET_CLASS_DETAIL_SUCCESS = "GET_CLASS_DETAIL_SUCCESS"
export const GET_CLASS_DETAIL_FAIL = "GET_CLASS_DETAIL_FAIL"

/**
 * Edit CLASS
 */
export const UPDATE_CLASS = "UPDATE_CLASS"
export const UPDATE_CLASS_SUCCESS = "UPDATE_CLASS_SUCCESS"
export const UPDATE_CLASS_FAIL = "UPDATE_CLASS_FAIL"
/**
 * CLASS TYPES
 */
export const GET_CLASS_TYPES = "GET_CLASS_TYPES"
export const GET_CLASS_TYPES_SUCCESS = "GET_CLASS_TYPES_SUCCESS"
export const GET_CLASS_TYPES_FAIL = "GET_CLASS_TYPES_FAIL"

/**
 * Reset CLASS
 */
export const RESET_CLASS = "RESET_CLASS"
export const RESET_CLASS_SUCCESS = "RESET_CLASS_SUCCESS"

/**
 * Add CLASS NOTES
 */
export const ADD_CLASS_NOTES = "ADD_CLASS_NOTES"
export const ADD_CLASS_NOTES_SUCCESS = "ADD_CLASS_NOTES_SUCCESS"
export const ADD_CLASS_NOTES_FAIL = "ADD_CLASS_NOTES_FAIL"

/**
 * Delete CLASS NOTES
 */
export const DELETE_CLASS_NOTES = "DELETE_CLASS_NOTES"
export const DELETE_CLASS_NOTES_SUCCESS = "DELETE_CLASS_NOTES_SUCCESS"
export const DELETE_CLASS_NOTES_FAIL = "DELETE_CLASS_NOTES_FAIL"

/*Get CLASS HAPPENING TYPES*/
export const GET_CLASS_HAPPENING_TYPES = "GET_CLASS_HAPPENING_TYPES"
export const GET_CLASS_HAPPENING_TYPES_SUCCESS =
  "GET_CLASS_HAPPENING_TYPES_SUCCESS"
export const GET_CLASS_HAPPENING_TYPES_FAIL = "GET_CLASS_HAPPENING_TYPES_FAIL"

/*Get CLASS HAPPENING*/
export const GET_CLASS_HAPPENING = "GET_CLASS_HAPPENING"
export const GET_CLASS_HAPPENING_SUCCESS = "GET_CLASS_HAPPENING_SUCCESS"
export const GET_CLASS_HAPPENING_FAIL = "GET_CLASS_HAPPENING_FAIL"

/*ADD CLASS HAPPENING*/
export const ADD_CLASS_HAPPENING = "ADD_CLASS_HAPPENING"
export const ADD_CLASS_HAPPENING_SUCCESS = "ADD_CLASS_HAPPENING_SUCCESS"
export const ADD_CLASS_HAPPENING_FAIL = "ADD_CLASS_HAPPENING_FAIL"

/*DELETE CLASS HAPPENING*/
export const DELETE_CLASS_HAPPENING = "DELETE_CLASS_HAPPENING"
export const DELETE_CLASS_HAPPENING_SUCCESS = "DELETE_CLASS_HAPPENING_SUCCESS"
export const DELETE_CLASS_HAPPENING_FAIL = "DELETE_CLASS_HAPPENING_FAIL"
