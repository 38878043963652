import { call, put, takeEvery } from "redux-saga/effects"
import Moment from "moment"

// Staff Redux States
import {
  GET_ACTIVITIES,
  ADD_ACTIVITY,
  DELETE_ACTIVITY,
  GET_ACTIVITY_DETAIL,
  UPDATE_ACTIVITY,
} from "./actionTypes"
import {
  getActivitiesSuccess,
  getActivitiesFail,
  addActivitiySuccess,
  addActivitiyFail,
  deleteActivitySuccess,
  deleteActivityFail,
  getActivityDetailSuccess,
  getActivityDetailFail,
  updateActivitiySuccess,
  updateActivitiyFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getActivities,
  addActivity,
  deleteActivity,
  getActivityDetail,
  updateActivity,
} from "helpers/backend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function* fetchActivities({ filter }) {
  try {
    const response = yield call(getActivities, filter)
    yield put(getActivitiesSuccess(response.Table))
  } catch (error) {
    yield put(getActivitiesFail(error))
  }
}

function* onAddActivity({ payload: activity }) {
  try {
    var formData = new FormData()
    let i = 1
    activity?.Attachment?.forEach(item => {
      formData.append("Attachment" + i, item)
      i++
    })
    formData.append("Data", JSON.stringify(activity))

    const response = yield call(addActivity, formData)
    yield put(addActivitiySuccess(response.Table))
    toast.success("Activity added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addActivitiyFail(error))
    toast.error("Activity addition failed", { autoClose: 2000 })
  }
}

function* onUpdateActivity({ payload: activity }) {
  try {
    var formData = new FormData()
    let i = 1
    activity?.Attachment?.forEach(item => {
      formData.append("Attachment" + i, item)
      i++
    })
    formData.append("Data", JSON.stringify(activity))

    const response = yield call(updateActivity, formData)
    yield put(updateActivitiySuccess(response.Table))
    toast.success("Activity updated successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateActivitiyFail(error))
    toast.error("Activity updatation failed", { autoClose: 2000 })
  }
}

function* onDeleteActivity({ payload: classSchedules }) {
  try {
    const response = yield call(deleteActivity, classSchedules)
    yield put(deleteActivitySuccess(response.Table))
    toast.success("Activity deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteActivityFail(error))
    toast.error("Activity deletion failed", { autoClose: 2000 })
  }
}

function* fetchActivityDetail({ payload: activityId }) {
  try {
    const response = yield call(getActivityDetail, activityId)
    let result = {
      ...response,
      Classes: {
        ClassID: response.ClassID,
        Title: response.ClassName,
      },
      ActivityStartDate: response.ActivityStartDate
        ? Moment(response.ActivityStartDate).format("DD MMM, YYYY")
        : "",
      ActivityStartTime: response.ActivityStartTime,
      ActivityEndTime: response.ActivityEndTime,
    }

    yield put(getActivityDetailSuccess(result))
  } catch (error) {
    yield put(getActivityDetailFail(error))
  }
}

function* staffScheduleSaga() {
  yield takeEvery(GET_ACTIVITIES, fetchActivities)
  yield takeEvery(ADD_ACTIVITY, onAddActivity)
  yield takeEvery(DELETE_ACTIVITY, onDeleteActivity)
  yield takeEvery(GET_ACTIVITY_DETAIL, fetchActivityDetail)
  yield takeEvery(UPDATE_ACTIVITY, onUpdateActivity)
}

export default staffScheduleSaga
