import countries from "../components/Common/Location/countries.json"
import states from "../components/Common/Location/states.json"
import cities from "../components/Common/Location/cities.json"

const getCountries = () => {
  return countries
}
const getStates = obj => {
  const statesData = states.filter(state => state.country_id == obj?.id)
  return statesData
}

const getCities = obj => {
  const citiesData = cities.filter(citi => citi.state_id == obj?.id)
  return citiesData
}
const getCountryByID = id => {
  return getCountries().filter(country => country.id == id)[0]
}
const getStateByID = id => {
  return states.filter(state => state.id == id)[0]
}

const getCityByID = id => {
  return cities.filter(city => city.id == id)[0]
}

export {
  getCountries,
  getStates,
  getCities,
  getCountryByID,
  getStateByID,
  getCityByID,
}
