import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../../components/Common/TableContainer"
import * as moment from "moment"

//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import DeleteModal from "../../../components/Common/DeleteModal"
import WeeklyTimeTable from "./pages/WeeklyTimeTable";
import {
  getCurriculums as onGetCurriculums,
  getCurriculumDetail as onGetCurriculumDetail,
  deleteCurriculum as onDeleteCurriculum,
} from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import {
  Col,
  Row,
  Table,
  Card,
  CardBody,
  Button,
  Badge,
  UncontrolledTooltip,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

import Spinners from "components/Common/Spinner"
import { ToastContainer } from "react-toastify"
import CurriculumModal from "./modal/CurriculumModal"
import { useDeepCompareEffect } from "hooks"
import Moment from "moment"
// import EnrollModal from "./modal/EnrollModal"

function Curriculums() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [toggleMenu, setToggleMenu] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [curriculumModal, setCurriculumModal] = useState(false)
  // const [enrollModal, setEnrollModal] = useState(false)
  const [curriculum, setCurriculum] = useState(false)
  const [edit, setEdit] = useState(false)

  useDeepCompareEffect(() => {
    dispatch(onGetCurriculums())
  }, [dispatch])

  //////////////////PROGRAM SCHEDULE STATE/////////////////////
  const { curriculums, loading } = useSelector(state => state.curriculum)
  const [isLoading, setLoading] = useState(loading)
  //////////////////////////////////////

  const handleAddClick = () => {
    // setCurriculum(null)
    // setEdit(false)
    // setCurriculumModal(true)
    navigate("/learning/curriculums/new")
  }

  const onClickEdit = arg => {
    // setCurriculum(arg)
    // setEdit(true)
    // setCurriculumModal(true)
  }

  const onClickDelete = arg => {
    setCurriculum(arg)
    setDeleteModal(true)
  }

  const onCloseModal = () => {
    setCurriculumModal(false)
  }

  const handleDelete = () => {
    if (curriculum && curriculum.CurriculumID) {
      dispatch(onDeleteCurriculum(curriculum.CurriculumID))
      setDeleteModal(false)
    }
  }

  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y")
    return date1
  }
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "Name",
        Cell: cellProps => {
          return <>{cellProps.row.original.Name}</>
        },
      },
      {
        Header: "Program",
        accessor: "ProgramName",
        Cell: cellProps => {
          return cellProps.value ? cellProps.value : ""
        },
      },
      {
        Header: "Shared",
        accessor: "IsShared",
        Cell: cellProps => {
          return cellProps.value == true ? "Yes" : "No"
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
               // to="#"
                 to={"/learning/curriculums/" + cellProps.row.original.RowID}
                className="text-success"
                // onClick={() => {
                //   onClickEdit(cellProps.row.original)
                // }}
              >
                
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  onClickDelete(cellProps.row.original)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDelete}
          onCloseClick={() => setDeleteModal(false)}
        />
      )}
      {curriculumModal && (
        <CurriculumModal
          show={curriculumModal}
          onEdit={edit}
          curriculum={curriculum}
          onCloseClick={() => onCloseModal()}
        />
      )}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Learning" breadcrumbItem="Curriculums List" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                {/* <WeeklyTimeTable/> */}
                  <div className="text-sm-end">
                    <Button
                      type="button"
                      color="primary"
                      className="btn "
                      onClick={handleAddClick}
                    >
                      <i className="mdi mdi-plus-circle-outline me-1" />
                      Add Curriculums
                    </Button>
                  </div>
                  {isLoading ? (
                    <Spinners setLoading={setLoading} />
                  ) : (
                    <TableContainer
                      columns={columns}
                      data={curriculums}
                      isGlobalFilter={true}
                      customPageSize={10}
                      isPagination={true}
                      filterable={false}
                      iscustomPageSizeOptions={true}
                      tableClass="align-middle table-nowrap table-check"
                      theadClass="table-light"
                      pagination="pagination pagination-rounded justify-content-end mb-2"
                    />
                  )}
                </CardBody>
              </Card>
        
            </Col>
          </Row>
        </div>
      </div>
      <ToastContainer />
    
    </React.Fragment>
  )
}
Curriculums.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Curriculums
