import React, { useState, useEffect } from "react"

//controls
import { Button, Col, Form, Input, Label, Row, FormFeedback } from "reactstrap"
import Flatpickr from "react-flatpickr"
import Switch from "react-switch"

//react form
import { FormProvider, useForm, Controller } from "react-hook-form"
import Select from "react-select"
import { createSelector } from "reselect"

//navigation
import { Link, useNavigate, useParams } from "react-router-dom"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

//custom hooks
import { useDeepCompareEffect } from "hooks"

//Date filter
import Moment from "moment"

import {
  addNewStaff as onAddNewStaff,
  getRoleTypes as onGetRoleTypes,
  updateStaff as onUpdateStaff,
  updateStaffPhoto as onUpdateStaffPhoto,
  resetStaff as onResetStaff,
} from "store/actions"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"
import LocationField from "components/Common/Location/Location"
import GenderField from "components/Common/Gender/Gender"
import PhotoField from "components/Common/Crop/PhotoField"
import Spinners from "components/Common/Spinner"
import { getUserImagePath } from "../../../helpers/imageService"
import { isEmpty } from "lodash"

function BasicInfoTab({ staff, loading }) {
  const navigate = useNavigate()
  const routeParams = useParams()
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(loading)

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    FirstName: yup
      .string()
      .required("Required")
      .min(3, "First name must be at least 3 characters"),
    LastName: yup.string().required("Required"),
    // LoginUserName: yup.string().required("Required"),
    IsActive: yup.bool(),
    Email: yup.string().required("Required").email("Must be a valid Email"),
    PhoneNumber: yup
      .string()
      .min(0, "Should be between 0 and 10")
      .max(10, "Should be between 0 and 10"),
    DateOfBirth: yup
      .string()
      .test("DateOfBirth", "Should be above 18 Years", value => {
        return value !== "" ? Moment().diff(Moment(value), "years") >= 18 : true
      }),
    Address: yup.string(),
    RoleTypes: yup.object().required("Required"),
    Countries: yup.object(),
    States: yup.object(),
    Cities: yup.object().required("Required"),
    ZipCode: yup.string().max(10, "Zipcode should be max 10 characters"),
    HireDate: yup.string(),
    SeparationDate: yup.string(),
    Photo: yup
      .mixed()
      .nullable(true)
      .transform((_, val) => (val ? String(val) : null)),
    Genders: yup.object().required("Required"),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      LoginUserName: "",
      FirstName: "",
      LastName: "",
      Email: "",
      PhoneNumber: "",
      DateOfBirth: "",
      Address: "",
      // CountryID: "",
      // StateID: "",
      // CityID: "",
      ZipCode: "",
      HireDate: "",
      SeparationDate: "",
      Photo: "",
      IsActive: true,
    },
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, setValue, getValues, trigger } = methods
  const { errors, isValid } = formState

  useDeepCompareEffect(() => {
    function updateStaffState() {
      dispatch(onGetRoleTypes())

      //reset form with server data
      reset(staff)
    }

    updateStaffState()
  }, [dispatch])

  //////////////////ROLE STATE/////////////////////
  const { roles, roletypes } = useSelector(state => state.role)
  ///////////////////////////////////////

  useEffect(() => {
    return () => {
      dispatch(onResetStaff())
    }
  }, [dispatch])

  const onCancelClick = () => {
    navigate("/staffs")
  }

  const handleSave = () => {
    trigger() //for form validation
    if (isValid) {
      const { id } = routeParams
      if (isEmpty(staff)) {
        dispatch(onAddNewStaff(getValues()))
      } else {
        dispatch(onUpdateStaffPhoto(getValues()))
        dispatch(onUpdateStaff(getValues()))
      }
      if (!isEmpty(id)) {
        navigate("/staffs")
      }
    }
  }

  if (isLoading) {
    return <Spinners setLoading={setLoading} />
  }

  return (
    <FormProvider {...methods}>
      <Row>
        <Col sm="4" className="order-md-3">
          <div className="mb-3">
            <PhotoField
              url={getUserImagePath(staff?.RowID, staff?.Photo ?? "")}
            />
          </div>
        </Col>
        <Col sm="4">
          <div className="mb-3">
            <Label>First Name</Label>
            <Controller
              name="FirstName"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    id="FirstName"
                    type="text"
                    required
                    invalid={!!errors.FirstName}
                  />
                  {errors?.FirstName?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.FirstName?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
          <div className="mb-3">
            <Label>Last Name</Label>
            <Controller
              name="LastName"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    id="LastName"
                    type="text"
                    required
                    invalid={!!errors.LastName}
                  />
                  {errors?.LastName?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.LastName?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
          {/* <div className="mb-3">
            <Label>Username</Label>
            <Controller
              name="LoginUserName"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    id="LoginUserName"
                    type="text"
                    required
                    invalid={!!errors.LoginUserName}
                  />
                  {errors?.LoginUserName?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.LoginUserName?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div> */}
          <GenderField />
          <div className="mb-3">
            <Label>Role</Label>
            <Controller
              name="RoleTypes"
              control={control}
              render={({ field }) => (
                <>
                  <Select
                    {...field}
                    id="RoleTypes"
                    options={roletypes}
                    getOptionLabel={option => option.RoleType}
                    getOptionValue={option => option.RoleTypeID}
                    required
                    aria-invalid={!!errors.RoleTypes}
                    classNamePrefix="select2-selection"
                  />
                  {errors?.RoleTypes?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.RoleTypes?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
        </Col>
        <Col sm="4">
          <div className="mb-3">
            <Label>Email</Label>
            <Controller
              name="Email"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    id="Email"
                    type="email"
                    required
                    invalid={!!errors.Email}
                  />
                  {errors?.Email?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.Email?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
          <div className="mb-3">
            <Label>Phone Number</Label>
            <Controller
              name="PhoneNumber"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    id="PhoneNumber"
                    type="number"
                    invalid={!!errors.PhoneNumber}
                  />
                  {errors?.PhoneNumber?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.PhoneNumber?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
          <div className="mb-3">
            <Label>Date of Birth</Label>
            <Controller
              name="DateOfBirth"
              control={control}
              render={({ field }) => (
                <>
                  <Flatpickr
                    {...field}
                    className="form-control d-block"
                    id="DateOfBirth"
                    options={{
                      dateFormat: "d M, Y",
                    }}
                    // onChange={date => setValue("DateOfBirth", date[0])}
                    onChange={(selectedDates, dateStr, instance) => {
                      setValue("DateOfBirth", dateStr)
                    }}
                  />
                  {errors?.DateOfBirth?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.DateOfBirth?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label>Hire Date</Label>
                <Controller
                  name="HireDate"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Flatpickr
                        {...field}
                        className="form-control d-block"
                        id="HireDate"
                        options={{
                          dateFormat: "d M, Y",
                        }}
                        //value={field.value}
                        //value={Date.parse(field.value)}
                        //onChange={date => setValue("HireDate", date[0])}
                        onChange={(selectedDates, dateStr, instance) => {
                          setValue("HireDate", dateStr)
                        }}
                      />
                      {errors?.HireDate?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.HireDate?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
                <Link
                  className="text-dark"
                  onClick={() => setValue("HireDate", "")}
                >
                  Clear
                </Link>
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label>Separation Date</Label>
                <Controller
                  name="SeparationDate"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Flatpickr
                        {...field}
                        className="form-control d-block"
                        id="SeparationDate"
                        options={{
                          dateFormat: "d M, Y",
                        }}
                        //value={field.value}
                        // value={Moment(field.value).toISOString()}
                        //onChange={date => setValue("SeparationDate", date[0])}
                        onChange={(selectedDates, dateStr, instance) => {
                          setValue("SeparationDate", dateStr)
                        }}
                      />
                      {errors?.SeparationDate?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.SeparationDate?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
                <Link
                  className="text-dark"
                  onClick={() => setValue("SeparationDate", "")}
                >
                  Clear
                </Link>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col sm="8">
          <LocationField />
        </Col>
        <div className="mb-3">
          <Label>Active</Label>
          <div>
            <Controller
              name="IsActive"
              control={control}
              render={({ field }) => (
                <>
                  <Switch
                    {...field}
                    id="IsActive"
                    checked={field.value}
                    uncheckedIcon={<Offsymbol />}
                    checkedIcon={<OnSymbol />}
                    className="me-1 mb-sm-8 mb-2"
                    onColor="#626ed4"
                  />
                </>
              )}
            />
          </div>
        </div>
      </Row>
      <div className="d-flex flex-wrap gap-2">
        <Button color="primary" className="btn" onClick={handleSave}>
          {isEmpty(staff) ? "Save" : "Update"}
        </Button>
        <Button
          type="submit"
          color="secondary"
          onClick={onCancelClick}
          className=""
        >
          Cancel
        </Button>
      </div>
    </FormProvider>
  )
}
export default BasicInfoTab
