import React, { useState, useEffect } from "react"

//controls
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Row,
  FormFeedback,
  InputGroup,
} from "reactstrap"
import Flatpickr from "react-flatpickr"
import Switch from "react-switch"

//react form
//react form
import {
  FormProvider,
  useForm,
  Controller,
  useFieldArray,
} from "react-hook-form"
import Select from "react-select"
import { createSelector } from "reselect"

//navigation
import { Link, useNavigate, useParams } from "react-router-dom"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

//custom hooks
import { useDeepCompareEffect } from "hooks"

import { isEmpty } from "lodash"
//Date filter
import Moment from "moment"

import {
  addLesson as onAddLesson,
  updateLesson as onUpdateLesson,
  getPrograms as onGetPrograms,
  resetLesson as onResetLesson,
} from "store/actions"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"
import Spinners from "components/Common/Spinner"
import PageModal from "components/Common/PageModal"
const formdefault = {
  LessonID: 0,
  Name: "",
  WeekDate: "",
  IsShared: false,
}
function BasicInfoTab({ lesson }) {
  const navigate = useNavigate()
  const routeParams = useParams()
  const dispatch = useDispatch()
  // const [isLoading, setLoading] = useState(loading)
  const { programs } = useSelector(state => state.program)
  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    LessonID: yup.number(),
    Name: yup.string().required("Required"),
    Programs: yup.object().required("Required"),
    WeekDate: yup.string().required("Required"),
    IsShared: yup.bool(),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: formdefault,
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, setValue, getValues, trigger } = methods
  const { errors, isValid } = formState

  useDeepCompareEffect(() => {
    function updateCurriculumState() {
      dispatch(onGetPrograms())
    }

    updateCurriculumState()
  }, [dispatch])

  useEffect(() => {
    const { id } = routeParams
    if (id === "new") {
      reset()
    } else {
      //reset form with server data
      const response = {
        ...lesson,
        Programs: {
          ProgramID: Number(lesson?.ProgramID),
          Name: lesson?.ProgramName,
        },
        WeekDate: lesson?.WeekDate
          ? Moment(lesson?.WeekDate).format("DD MMM, YYYY")
          : "",
      }
      reset(response)
    }

    return () => {
      //  dispatch(onResetLesson())
    }
  }, [dispatch, lesson])

  const onCancelClick = () => {
    navigate("/learning/lesson-plans")
  }

  const handleSave = () => {
    trigger() //for form validation
    if (isValid) {
      const { id } = routeParams

      const newLesson = {
        ...getValues(),
        id: id,
        LessonID: lesson?.LessonID,
      }
      if (id === "new") {
        dispatch(onAddLesson(newLesson))
      } else {
        dispatch(onUpdateLesson(newLesson))
      }

      navigate("/learning/lesson-plans")
    }
  }

  return (
    <FormProvider {...methods}>
      <Row>
        <Col lg={6}>
          <div className="mb-3">
            <Label>Name</Label>
            <Controller
              name="Name"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    id="Name"
                    type="text"
                    required
                    invalid={!!errors.Name}
                  />
                  {errors?.Name?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.Name?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
          <div className="mb-3">
            <Label>Program</Label>
            <Controller
              name="Programs"
              control={control}
              render={({ field }) => (
                <>
                  <Select
                    {...field}
                    id="Programs"
                    options={programs}
                    getOptionLabel={option => option.Name}
                    getOptionValue={option => option.ProgramID}
                    required
                    aria-invalid={!!errors.Programs}
                    classNamePrefix="select2-selection"
                  />
                  {errors?.Programs?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.Programs?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>

          <div className="mb-3">
            <Label>Week Date</Label>
            <Controller
              name="WeekDate"
              control={control}
              render={({ field }) => (
                <>
                  <Flatpickr
                    {...field}
                    className="form-control d-block"
                    id="WeekDate"
                    options={{
                      dateFormat: "d M, Y",
                    }}
                    onChange={(selectedDates, dateStr, instance) => {
                      setValue("WeekDate", dateStr)
                    }}
                    required
                  />
                  {errors?.WeekDate?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.WeekDate?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
          <div className="mb-3">
            <Label>Share with parent</Label>
            <div>
              <Controller
                name="IsShared"
                control={control}
                render={({ field }) => (
                  <>
                    <Switch
                      {...field}
                      id="IsShared"
                      checked={field.value}
                      uncheckedIcon={<Offsymbol />}
                      checkedIcon={<OnSymbol />}
                      className="me-1 mb-sm-8 mb-2"
                      onColor="#626ed4"
                    />
                  </>
                )}
              />
            </div>
          </div>
        </Col>
      </Row>
      <div className="d-flex flex-wrap gap-2">
        <Button color="primary" className="btn" onClick={handleSave}>
          {routeParams.id === "new" ? "Save" : "Update"}
        </Button>
        <Button
          type="submit"
          color="secondary"
          onClick={onCancelClick}
          className=""
        >
          Cancel
        </Button>
      </div>
    </FormProvider>
  )
}
export default BasicInfoTab
