/*Get USER Profile */
export const GET_PROFILE = "GET_PROFILE"
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS"
export const GET_PROFILE_FAIL = "GET_PROFILE_FAIL"

export const UPDATE_IMPERSONATE_USER = "UPDATE_IMPERSONATE_USER"
export const UPDATE_IMPERSONATE_USER_SUCCESS = "UPDATE_IMPERSONATE_USER_SUCCESS"
export const UPDATE_IMPERSONATE_USER_FAIL = "UPDATE_IMPERSONATE_USER_FAIL"

export const DELETE_IMPERSONATE_USER = "DELETE_IMPERSONATE_USER"
export const DELETE_IMPERSONATE_USER_SUCCESS = "DELETE_IMPERSONATE_USER_SUCCESS"
export const DELETE_IMPERSONATE_USER_FAIL = "DELETE_IMPERSONATE_USER_FAIL"
