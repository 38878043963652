import { call, put, takeEvery } from "redux-saga/effects"

// Addon Redux States
import {
  GET_LESSONS,
  DELETE_LESSON,
  ADD_LESSON,
  GET_LESSON_DETAIL,
  UPDATE_LESSON,
  GET_NOTECATEGORIES,
  RESET_LESSON,
  ADD_LESSON_SCHEDULE,
  GET_LESSONSCHEDULES_BY_LESSON,
  UPDATE_LESSONSCHEDULE_DETAIL,
  GET_LESSONSCHEDULE_DETAIL_BYLSD,
  DELETE_LESSON_SCHEDULE,
  DELETE_LESSON_SCHEDULE_DETAIL,
} from "./actionTypes"
import {
  getLessonsSuccess,
  getLessonsFail,
  deleteLessonSuccess,
  deleteLessonFail,
  addLessonSuccess,
  addLessonFail,
  getLessonDetailSuccess,
  getLessonDetailFail,
  updateLessonSuccess,
  updateLessonFail,
  resetLessonSuccess,
  getNoteCategoriesSuccess,
  getNoteCategoriesFail,
  addLessonScheduleSuccess,
  addLessonScheduleFail,
  getLessonSchedulesByLessonSuccess,
  getLessonSchedulesByLessonFail,
  updateLessonScheduleDetailSuccess,
  updateLessonScheduleDetailFail,
  getLessonScheduleDetailbyLSDSuccess,
  getLessonScheduleDetailbyLSDFail,
  deleteLessonScheduleSuccess,
  deleteLessonScheduleFail,
  deleteLessonScheduleDetailSuccess,
  deleteLessonScheduleDetailFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getLessons,
  deleteLesson,
  addNewLesson,
  getLessonDetail,
  updateLesson,
  //getNoteCategories,
  addLessonSchedule,
  deleteLessonSchedule,
  getLessonSchedulesByLesson,
  updateLessonScheduleDetail,
  getLessonScheduleDetailbyLSD,
  deleteLessonScheduleDetail,
} from "helpers/backend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function* fetchLessons() {
  try {
    const response = yield call(getLessons)
    yield put(getLessonsSuccess(response))
  } catch (error) {
    yield put(getLessonsFail(error))
  }
}

function* onDeleteLesson({ payload: lessonID }) {
  try {
    const response = yield call(deleteLesson, lessonID)
    yield put(deleteLessonSuccess(response))
    toast.success("Lesson deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteLessonFail(error))
    toast.error("Lesson deletion failed", { autoClose: 2000 })
  }
}

function* onAddLesson({ payload: lesson }) {
  try {
    const lessonNew = {
      ...lesson,
      ProgramID: lesson.Programs?.ProgramID ? lesson.Programs?.ProgramID : 0,
    }
    const response = yield call(addNewLesson, lessonNew)
    yield put(addLessonSuccess(response[0]))
    toast.success("Lesson added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addLessonFail(error))
    toast.error("Lesson addition failed", { autoClose: 2000 })
  }
}

function* fetchLessonDetail({ RowID }) {
  try {
    const response = yield call(getLessonDetail, RowID)
    let result = response[0]
    result = {
      ...result,
    }
    yield put(getLessonDetailSuccess(result))
  } catch (error) {
    yield put(getLessonDetailFail(error))
  }
}

function* onUpdateLesson({ payload: lesson }) {
  debugger
  try {
    const lessonNew = {
      ...lesson,
      ProgramID: lesson?.Programs?.ProgramID ?? 0,
    }
    const response = yield call(updateLesson, lessonNew)
   
    let result = response[0]
    yield put(updateLessonSuccess(result))
    toast.success("Lesson updated successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateLessonFail(error))
    toast.error("Lesson updation failed", { autoClose: 2000 })
  }
}

// function* fetchNoteCategories() {
//   try {
//     const response = yield call(getNoteCategories)
//     yield put(getNoteCategoriesSuccess(response))
//   } catch (error) {
//     yield put(getNoteCategoriesFail(error))
//   }
// }

function* onResetLesson() {
  try {
    yield put(resetLessonSuccess({}))
  } catch (error) {}
}

function* onAddLessonSchedule({ payload: lessonschedule }) {
  try {
    debugger
    const lessonscheduleNew = {
      ...lessonschedule,
      NoteCategoryID: lessonschedule.NoteCategories?.NoteCategoryID
        ? lessonschedule.NoteCategories?.NoteCategoryID
        : 0,
    }

    const response = yield call(addLessonSchedule, lessonscheduleNew)

    yield put(addLessonScheduleSuccess(response[0]))
    toast.success("Lesson Schedule added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addLessonScheduleFail(error))
    toast.error("Lesson Schedule addition failed", { autoClose: 2000 })
  }
}
function* fetchLessonSchedulesByLesson({ RowID }) {
  try {
    const response = yield call(getLessonSchedulesByLesson, RowID)
    yield put(getLessonSchedulesByLessonSuccess(response))
  } catch (error) {
    yield put(getLessonSchedulesByLessonFail(error))
  }
}
function* onUpdateLessonScheduleDetail({ payload: lessonScheduleDetail }) {
  try {
    const lessonScheduleDetailNew = {
      ...lessonScheduleDetail,
      NoteCategoryID: lessonScheduleDetail.NoteCategories?.NoteCategoryID
        ? lessonScheduleDetail.NoteCategories?.NoteCategoryID
        : 0
    }

    const response = yield call(
      updateLessonScheduleDetail,
      lessonScheduleDetailNew
    )
    let result = response[0]
    yield put(updateLessonScheduleDetailSuccess(result))
    toast.success("Lesson Schedule detail updated successfully", {
      autoClose: 2000,
    })
  } catch (error) {
    yield put(updateLessonScheduleDetailFail(error))
    toast.error("Lesson Schedule detail updation failed", {
      autoClose: 2000,
    })
  }
}

function* fetchLessonScheduleDetailsByLSD({ payload: lessonScheduleDetail }) {
  try {
    const response = yield call(
      getLessonScheduleDetailbyLSD,
      lessonScheduleDetail
    )
    yield put(getLessonScheduleDetailbyLSDSuccess(response))
  } catch (error) {
    yield put(getLessonScheduleDetailbyLSDFail(error))
  }
}

function* onDeleteLessonSchedule({ payload: LessonScheduleID }) {
  try {
    const response = yield call(deleteLessonSchedule, LessonScheduleID)
    yield put(deleteLessonScheduleSuccess(response))
    toast.success("Lesson schedule deleted successfully", {
      autoClose: 2000,
    })
  } catch (error) {
    yield put(deleteLessonScheduleFail(error))
    toast.error("Lesson schedule deletion failed", { autoClose: 2000 })
  }
}

function* onDeleteLessonScheduleDetail({ payload: LessonScheduleDetailID }) {
  try {
    const response = yield call(
      deleteLessonScheduleDetail,
      LessonScheduleDetailID
    )
    yield put(deleteLessonScheduleDetailSuccess(response))
    toast.success("Lesson schedule detail deleted successfully", {
      autoClose: 2000,
    })
  } catch (error) {
    yield put(deleteLessonScheduleDetailFail(error))
    toast.error("Lesson schedule detail deletion failed", {
      autoClose: 2000,
    })
  }
}

function* lessonSaga() {
  yield takeEvery(GET_LESSONS, fetchLessons)
  yield takeEvery(DELETE_LESSON, onDeleteLesson)
  yield takeEvery(ADD_LESSON, onAddLesson)
  yield takeEvery(GET_LESSON_DETAIL, fetchLessonDetail)
  yield takeEvery(UPDATE_LESSON, onUpdateLesson)
  //yield takeEvery(GET_NOTECATEGORIES, fetchNoteCategories)
  yield takeEvery(RESET_LESSON, onResetLesson)
  yield takeEvery(ADD_LESSON_SCHEDULE, onAddLessonSchedule)
  yield takeEvery(GET_LESSONSCHEDULES_BY_LESSON, fetchLessonSchedulesByLesson)
  yield takeEvery(UPDATE_LESSONSCHEDULE_DETAIL, onUpdateLessonScheduleDetail)
  yield takeEvery(
    GET_LESSONSCHEDULE_DETAIL_BYLSD,
    fetchLessonScheduleDetailsByLSD
  )
  yield takeEvery(DELETE_LESSON_SCHEDULE, onDeleteLessonSchedule)
  yield takeEvery(DELETE_LESSON_SCHEDULE_DETAIL, onDeleteLessonScheduleDetail)
}

export default lessonSaga
