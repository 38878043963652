import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../../components/Common/TableContainer"
import * as moment from "moment"

//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import DeleteModal from "../../../components/Common/DeleteModal"
import WeeklyTimeTable from "./pages/WeeklyTimeTable";
import {
  getLessons as onGetLessons,
  getLessonDetail as onGetLessonDetail,
  deleteLesson as onDeleteLesson,
} from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import {
  Col,
  Row,
  Table,
  Card,
  CardBody,
  Button,
  Badge,
  UncontrolledTooltip,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

import Spinners from "components/Common/Spinner"
import { ToastContainer } from "react-toastify"
import LessonPlanModal from "./modal/LessonPlanModal"
import { useDeepCompareEffect } from "hooks"
import Moment from "moment"
// import EnrollModal from "./modal/EnrollModal"

function LessonPlans() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [toggleMenu, setToggleMenu] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [lessonModal, setLessonModal] = useState(false)
  // const [enrollModal, setEnrollModal] = useState(false)
  const [lesson, setLesson] = useState(false)
  const [edit, setEdit] = useState(false)

  useDeepCompareEffect(() => {
    dispatch(onGetLessons())
  }, [dispatch])

  //////////////////PROGRAM SCHEDULE STATE/////////////////////
  const { lessons, loading } = useSelector(state => state.lesson)
  const [isLoading, setLoading] = useState(loading)
  //////////////////////////////////////

  const handleAddClick = () => {
    setLesson(null)
    setEdit(false)
    setLessonModal(true)
    navigate("/learning/lesson-plans/new")
  }

  const onClickEdit = arg => {
  //  setLesson(arg)
   // setEdit(true)
   // setLessonModal(true)
  }

  const onClickDelete = arg => {
    setLesson(arg)
    setDeleteModal(true)
  }

  const onCloseModal = () => {
   // setLesson(null)
    setLessonModal(false)
  }

  const handleDelete = () => {
    if (lesson && lesson.LessonID) {
      dispatch(onDeleteLesson(lesson.LessonID))
      setDeleteModal(false)
    }
  }

  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y")
    return date1
  }
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "Name",
        Cell: cellProps => {
          return <>{cellProps.row.original.Name}</>
        },
      },
      {
        Header: "Program",
        accessor: "ProgramName",
        Cell: cellProps => {
          return cellProps.value ? cellProps.value : ""
        },
      },
      {
        Header: "Week Date",
        accessor: "WeekDate",
        Cell: cellProps => {
          return cellProps.value
            ? moment(new Date(cellProps.value)).format("DD MMM Y")
            : ""
        },
      },
      {
        Header: "Shared",
        accessor: "IsShared",
        Cell: cellProps => {
          return cellProps.value == true ? "Yes" : "No"
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
             
                  to={"/learning/lesson-plans/" + cellProps.row.original.RowID}
                className="text-success"
               // onClick={() => {
               //   onClickEdit(cellProps.row.original)
               // }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  onClickDelete(cellProps.row.original)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDelete}
          onCloseClick={() => setDeleteModal(false)}
        />
      )}
      {lessonModal && (
        <LessonPlanModal
          show={lessonModal}
          onEdit={edit}
          lesson={lesson}
          onCloseClick={() => onCloseModal()}
        />
      )}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Learing" breadcrumbItem="Lesson-Plan List" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <div className="text-sm-end">
                    <Button
                      type="button"
                      color="primary"
                      className="btn "
                      onClick={handleAddClick}
                    >
                      <i className="mdi mdi-plus-circle-outline me-1" />
                      Add lesson plans
                    </Button>
                  </div>
                  {isLoading ? (
                    <Spinners setLoading={setLoading} />
                  ) : (
                    <TableContainer
                      columns={columns}
                      data={lessons}
                      isGlobalFilter={true}
                      customPageSize={10}
                      isPagination={true}
                      filterable={false}
                      iscustomPageSizeOptions={true}
                      tableClass="align-middle table-nowrap table-check"
                      theadClass="table-light"
                      pagination="pagination pagination-rounded justify-content-end mb-2"
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}
LessonPlans.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default LessonPlans
