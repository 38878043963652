import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
//controls
import {
  Col,
  Label,
  Row,
  Input,
  Modal,
  ModalBody,
  InputGroup,
  FormFeedback,
  ModalHeader,
  ModalFooter,
  Button,
  NavItem,
  NavLink,
} from "reactstrap"

//react form
import { FormProvider, useForm, Controller } from "react-hook-form"
import Select from "react-select"
import { createSelector } from "reselect"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

import { getAdultRelations as onGetAdultRelations } from "store/actions"

//custom hooks
import { useDeepCompareEffect } from "hooks"
import { getChildImagePath } from "helpers/imageService"
import { Link } from "react-router-dom"
import { isEmpty } from "lodash"

//Date filter
import Moment from "moment"
import PaymentModal from "../modal/PaymentModal"
import ChildModal from "../modal/ChildModal"

const Relations = () => {
  const dispatch = useDispatch()
  const [paymentModal, setPaymentModal] = useState(false)
  const [childModal, setChildModal] = useState(false)
  const [childID, setChildID] = useState(0)

  //////////////////Adult STATE/////////////////////
  const { adult, relations } = useSelector(state => state.adult)
  //////////////////////////////////////

  useEffect(() => {
    dispatch(onGetAdultRelations(adult.AdultID))
  }, [dispatch])

  const onPaymentModalClick = id => {
    setPaymentModal(true)
    setChildID(id)
  }

  const onChildModalClick = () => {
    setChildModal(true)
  }

  const onCloseModal = () => {
    setPaymentModal(false)
    setChildModal(false)
    setChildID(0)
    dispatch(onGetAdultRelations(adult.AdultID))
  }

  return (
    <React.Fragment>
      {!isEmpty(adult) ? (
        <>
          {paymentModal && (
            <PaymentModal
              show={paymentModal}
              onCloseClick={() => onCloseModal()}
              childID={childID}
            />
          )}
          {childModal && (
            <ChildModal show={childModal} onCloseClick={() => onCloseModal()} />
          )}
        </>
      ) : (
        ""
      )}
      <div className="border-bottom card-body">
        <div className="d-flex align-items-center">
          <h5 className="mb-0 card-title flex-grow-1">
            Related Children{" "}
            <span className="btn btn-success btn-sm">{relations.length}</span>
          </h5>

          <div className="flex-shrink-0">
            <a
              className="btn btn-primary me-1"
              onClick={() => onChildModalClick()}
            >
              Add Child
            </a>
          </div>
        </div>
      </div>
      <div className="mt-3">
        {relations &&
          relations.map((item, index) => {
            return (
              <div key={index} className="border-bottom mt-3">
                <div className="d-flex align-items-center">
                  <div className="me-2">
                    <img
                      className="rounded-circle avatar-xs"
                      src={getChildImagePath(item.RowID, "image.jpeg")}
                      alt="Header Avatar"
                    />
                  </div>
                  <div>
                    <h5 className="font-size-14 mb-1">
                      <Link className="text-dark" to="#">
                        {item.Name}
                      </Link>
                    </h5>
                    <p className="text-muted mb-0">
                      {adult.Name + "'s relation : " + item.Relation}
                    </p>

                    <p className="text-muted mb-0">{item.Permissions}</p>
                  </div>
                </div>
                <p hidden={item.Payment.IsPaid} className="text-muted mb-0">
                  <div className="p-3">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <div>
                        <h5 className="m-0 pt-2">
                          <span className="me-2"> Unpaid Services</span>
                          <Button
                            className="btn btn-danger waves-effect btn-sm"
                            onClick={() => onPaymentModalClick(item.ChildID)}
                          >
                            {"$" + Number(item.Payment.Amount).toFixed(2)}
                          </Button>
                        </h5>
                      </div>
                    </div>
                    {item.Payment.StartDate &&
                      Moment(item.Payment.StartDate).format("DD MMM yyyy")}
                    {" - "}
                    {item.Payment.EndDate &&
                      Moment(item.Payment.EndDate).format("DD MMM yyyy")}
                  </div>
                </p>
              </div>
            )
          })}
      </div>
    </React.Fragment>
  )
}

export default Relations
