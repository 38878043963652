import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Col, Row, Card, CardBody } from "reactstrap"

import { ToastContainer } from "react-toastify"
import {
  getReports as onGetReports,
  getReportTypes as onGetReportTypes,
} from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { isEmpty } from "lodash"

function Report() {
  const dispatch = useDispatch()
  const [reportType, setReportType] = useState("All")
  const [filterData, setFilterData] = useState([])

  useEffect(() => {
    dispatch(onGetReports(""))
    dispatch(onGetReportTypes())
  }, [dispatch])

  const { reports, reporttypes, loading } = useSelector(state => state.report)

  useEffect(() => {
    setFilterData(reports)
  }, [reports])

  const FilterRecords = obj => {
    setReportType(obj.ReportType)
    if (obj.ReportTypeID > 1) {
      let filter = reports.filter(x => x.ReportTypeID == obj.ReportTypeID)
      setFilterData(filter)
    } else {
      setFilterData(reports)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Reports" />
          <Row>
            <Col xl={3}>
              <Card>
                <CardBody>
                  <div className="table-responsive">
                    {/* <table> */}
                    {reporttypes &&
                      (reporttypes || []).map((item, index) => {
                        return (
                          <div key={"item" + item.ReportTypeID}>
                            {/* <tr key={"item" + item.ReportTypeID} scope="row">
                              <td> */}
                            <Link to="#" onClick={() => FilterRecords(item)}>
                              {item.ReportType}
                            </Link>
                            {/* </td>
                            </tr> */}
                          </div>
                        )
                      })}
                    {/* </table> */}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={6}>
              <h4>{reportType}</h4>
              {isEmpty(filterData) ? (
                "No Report Available"
              ) : (
                <>
                  {filterData &&
                    (filterData || []).map((item, index) => {
                      return (
                        <Card key={"report" + index}>
                          <CardBody>
                            <h5 className="fs-17 mb-2">
                              <Link
                                to={"/report/" + item.RowID}
                                className="text-dark"
                              >
                                {item.ReportTitle}
                              </Link>
                            </h5>
                            <ul className="list-inline mb-0">
                              <li className="list-inline-item">
                                <p className="text-muted fs-14 mb-1">
                                  {item.Description}
                                </p>
                              </li>
                            </ul>
                            <div className="mt-3 hstack gap-2">
                              <span className="badge rounded-1 badge-soft-success">
                                {item.ReportType}
                              </span>
                            </div>
                          </CardBody>
                        </Card>
                      )
                    })}
                </>
              )}
            </Col>
          </Row>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}
Report.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Report
