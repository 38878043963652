const ChildrenDailyColumns = [
  {
    Header: "Child",
    accessor: "Name",
  },
  {
    Header: "Class",
    accessor: "ClassTitle",
  },
  {
    Header: "Check In",
    accessor: "CheckIn",
  },
  {
    Header: "Check Out",
    accessor: "CheckOut",
  },
]

const ChildrenWeeklyColumns = [
  {
    Header: "Child",
    accessor: "Name",
  },
  {
    Header: "Date",
    accessor: "HappeningDate",
  },
  {
    Header: "Class",
    accessor: "ClassTitle",
  },
  {
    Header: "Check In",
    accessor: "CheckIn",
  },
  {
    Header: "Check Out",
    accessor: "CheckOut",
  },
]

const StaffDailyColumns = [
  {
    Header: "Staff",
    accessor: "Name",
  },
  {
    Header: "Class",
    accessor: "ClassTitle",
  },
  {
    Header: "Check In",
    accessor: "CheckIn",
  },
  {
    Header: "Check Out",
    accessor: "CheckOut",
  },
]

const ChildrenAttendanceColumns = [
  {
    Header: "Child",
    accessor: "Name",
  },
  {
    Header: "Date",
    accessor: "HappeningDate",
  },
  {
    Header: "Check In",
    accessor: "CheckIn",
  },
  {
    Header: "Check Out",
    accessor: "CheckOut",
  },
]

const ChildrenAllergiesColumns = [
  {
    Header: "Child",
    accessor: "Name",
  },
]

const ChildrenEmergencyContactsColumns = [
  {
    Header: "Child",
    accessor: "Name",
  },
]

const StaffEmergencyContactsColumns = [
  {
    Header: "Child",
    accessor: "Name",
  },
]

const StaffWeeklyColumns = [
  {
    Header: "Child",
    accessor: "Name",
  },
]

const AbsentChildrenColumns = [
  {
    Header: "Child",
    accessor: "Name",
  },
]

const AbsentStaffColumns = [
  {
    Header: "Child",
    accessor: "Name",
  },
]

export {
  ChildrenDailyColumns,
  ChildrenWeeklyColumns,
  StaffDailyColumns,
  ChildrenAttendanceColumns,
  ChildrenAllergiesColumns,
  ChildrenEmergencyContactsColumns,
  StaffEmergencyContactsColumns,
  StaffWeeklyColumns,
  AbsentChildrenColumns,
  AbsentStaffColumns,
}
