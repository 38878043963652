import {
  GET_CHARTS_DATA_SUCCESS,
  GET_CHARTS_DATA_FAIL,
  GET_SCHEDULED_DATA_SUCCESS,
  GET_SCHEDULED_DATA_FAIL,
  GET_LIVE_DATA_SUCCESS,
  GET_LIVE_DATA_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  title: "",
  cards: [],
  centerstats: [],
  centercapacity: [],
  attendance: [],
  classstatusdata: [],
  enrollments: [],
  birthday: [],
  messages: [],
  scheduledclass: [],
  scheduledteacher: [],
  scheduledchildren: [],
  liveclass: [],
  liveteacher: [],
  livechildren: [],
}

const Dashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CHARTS_DATA_SUCCESS:
      return {
        ...state,
        title: action.payload.CenterTitle,
        cards: action.payload.Summary,
        centerstats: action.payload.CenterStats,
        centercapacity: action.payload.CenterCapacity,
        attendance: action.payload.Attendance,
        classstatusdata: action.payload.ClassStatusData,
        enrollments: action.payload.Enrollment,
        birthday: action.payload.Birthday,
        messages: action.payload.Message,
        loading: true,
      }
    case GET_CHARTS_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_SCHEDULED_DATA_SUCCESS:
      return {
        ...state,
        scheduledclass: action.payload.ScheduledClass,
        scheduledteacher: action.payload.ScheduledTeacher,
        scheduledchildren: action.payload.ScheduledChildren,
        loading: true,
      }
    case GET_SCHEDULED_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_LIVE_DATA_SUCCESS:
      return {
        ...state,
        liveclass: action.payload.LiveClass,
        liveteacher: action.payload.LiveTeacher,
        livechildren: action.payload.LiveChildren,
        loading: true,
      }
    case GET_LIVE_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default Dashboard
