import React from "react"
import { Controller, useFormContext } from "react-hook-form"
import Select from "react-select"
import { Input, Label, FormFeedback, Row, Col } from "reactstrap"

import {
  getCountries,
  getStates,
  getCities,
} from "../../../helpers/location_helper"

export default function LocationField(props) {
  const methods = useFormContext()
  const { control, formState, setValue, getValues } = methods
  const { errors } = formState

  let countryList = getCountries()

  let availableStates = getStates(getValues("Countries"))
  let availableCities = getCities(getValues("States"))

  return (
    <Row>
      <Col sm="12">
        <div className="mb-3">
          <Label>Address</Label>
          <Controller
            name="Address"
            control={control}
            value=""
            render={({ field }) => (
              <textarea
                {...field}
                rows={3}
                className="form-control mb-3"
                id="Address"
              />
            )}
          />
        </div>
      </Col>
      <Col sm="4">
        <div className="mb-3">
          <Label>Country</Label>
          <Controller
            name="Countries"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                id="Countries"
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                options={countryList}
                classNamePrefix="select2-selection"
              />
            )}
          />
        </div>
      </Col>
      <Col sm="4">
        <div className="mb-3">
          <Label>State</Label>
          <Controller
            name="States"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                id="States"
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                options={availableStates}
                classNamePrefix="select2-selection"
              />
            )}
          />
        </div>
      </Col>
      <Col sm="4">
        <div className="mb-3">
          <Label>City</Label>
          <Controller
            name="Cities"
            control={control}
            render={({ field }) => (
              <>
                <Select
                  {...field}
                  id="Cities"
                  required
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  aria-invalid={!!errors.Cities}
                  options={availableCities}
                  classNamePrefix="select2-selection"
                />
                {errors?.Cities?.message ? (
                  <FormFeedback type="invalid" className="d-block">
                    {errors?.Cities?.message}
                  </FormFeedback>
                ) : null}
              </>
            )}
          />
        </div>
      </Col>
      <Col sm="12">
        <div className="mb-3">
          <Label>Zip Code</Label>
          <Controller
            name="ZipCode"
            control={control}
            render={({ field }) => (
              <Input {...field} id="ZipCode" type="number" />
            )}
          />
        </div>
      </Col>
    </Row>
  )
}
