import {
  GET_NOTES,
  GET_NOTES_FAIL,
  GET_NOTES_SUCCESS,
  DELETE_NOTE,
  DELETE_NOTE_FAIL,
  DELETE_NOTE_SUCCESS,
  ADD_NOTE,
  ADD_NOTE_SUCCESS,
  ADD_NOTE_FAIL,
  GET_NOTE_DETAIL,
  GET_NOTE_DETAIL_SUCCESS,
  GET_NOTE_DETAIL_FAIL,
  UPDATE_NOTE,
  UPDATE_NOTE_SUCCESS,
  UPDATE_NOTE_FAIL,
  RESET_NOTE_SUCCESS,
  RESET_NOTE,
  ADD_NOTE_SCHEDULE,
  ADD_NOTE_SCHEDULE_SUCCESS,
  ADD_NOTE_SCHEDULE_FAIL,
  DELETE_NOTE_SCHEDULE,
  DELETE_NOTE_SCHEDULE_SUCCESS,
  DELETE_NOTE_SCHEDULE_FAIL,
  GET_NOTESCHEDULES_BY_NOTE,
  GET_NOTESCHEDULES_BY_NOTE_SUCCESS,
  GET_NOTESCHEDULES_BY_NOTE_FAIL,
  UPDATE_NOTESCHEDULE_DETAIL,
  UPDATE_NOTESCHEDULE_DETAIL_SUCCESS,
  UPDATE_NOTESCHEDULE_DETAIL_FAIL,
  GET_NOTESCHEDULE_DETAIL_BYNSD,
  GET_NOTESCHEDULE_DETAIL_BYNSD_SUCCESS,
  GET_NOTESCHEDULE_DETAIL_BYNSD_FAIL,
  DELETE_NOTE_SCHEDULE_DETAIL,
  DELETE_NOTE_SCHEDULE_DETAIL_SUCCESS,
  DELETE_NOTE_SCHEDULE_DETAIL_FAIL

} from "./actionTypes"


//  NOTE


export const getAllNote = () => ({
  type: GET_NOTES,
})

export const getAllNoteSuccess = notes=> ({
  type: GET_NOTES_SUCCESS,
  payload: notes,
})

export const getAllNoteFail = error => ({
  type: GET_NOTES_FAIL,
  payload: error,
})

export const deleteNote = noteID => ({
  type: DELETE_NOTE,
  payload: noteID,
})

export const deleteNoteSuccess = note => ({
  type: DELETE_NOTE_SUCCESS,
  payload: note,
})

export const deleteNoteFail = error => ({
  type: DELETE_NOTE_FAIL,
  payload: error,
})


export const addNote = note => ({
  type: ADD_NOTE,
  payload: note,
})

export const addNoteSuccess = note => ({
  type: ADD_NOTE_SUCCESS,
  payload: note,
})

export const addNoteFail = error => ({
  type: ADD_NOTE_FAIL,
  payload: error,
})


export const getNoteDetail = RowID => ({
  type: GET_NOTE_DETAIL,
  RowID,
})

export const getNoteDetailSuccess = note => ({
  type: GET_NOTE_DETAIL_SUCCESS,
  payload: note,
})

export const getNoteDetailFail = error => ({
  type: GET_NOTE_DETAIL_FAIL,
  payload: error,
})

export const updateNote= note => ({
  type: UPDATE_NOTE,
  payload: note,
})

export const updateNoteSuccess = note => ({
  type: UPDATE_NOTE_SUCCESS,
  payload: note,
})

export const updateNoteFail = error => ({
  type: UPDATE_NOTE_FAIL,
  payload: error,
})


export const resetNote= () => ({
  type: RESET_NOTE
 
})

export const resetNoteSuccess = note => ({
  type: RESET_NOTE_SUCCESS,
  payload: note,
})

export const addNoteSchedule = noteschedule => ({
  type: ADD_NOTE_SCHEDULE,
  payload: noteschedule,
})

export const addNoteScheduleSuccess = noteschedule => ({
  type: ADD_NOTE_SCHEDULE_SUCCESS,
  payload: noteschedule,
})

export const addNoteScheduleFail = error => ({
  type: ADD_NOTE_SCHEDULE_FAIL,
  payload: error,
})

//  Note schedules

export const getNoteSchedulesByNote = (RowID) => ({
  type: GET_NOTESCHEDULES_BY_NOTE,
  RowID
})

export const getNoteSchedulesByNoteSuccess = notes => ({
  type: GET_NOTESCHEDULES_BY_NOTE_SUCCESS,
  payload: notes,
})

export const getNoteSchedulesByNoteFail = error => ({
  type: GET_NOTESCHEDULES_BY_NOTE_FAIL,
  payload: error,
})

export const updateNoteScheduleDetail = noteScheduleDetail => ({
  type: UPDATE_NOTESCHEDULE_DETAIL,
  payload: noteScheduleDetail
  
})

export const updateNoteScheduleDetailSuccess = noteScheduleDetail => ({
  type: UPDATE_NOTESCHEDULE_DETAIL_SUCCESS,
  payload: noteScheduleDetail,
})

export const updateNoteScheduleDetailFail = error => ({
  type: UPDATE_NOTESCHEDULE_DETAIL_FAIL,
  payload: error,
})


export const getNoteScheduleDetailbyNSD = noteScheduleDetail => ({
  type: GET_NOTESCHEDULE_DETAIL_BYNSD,
  payload: noteScheduleDetail,
})

export const getNoteScheduleDetailbyNSDSuccess = noteScheduleDetail => ({
  type: GET_NOTESCHEDULE_DETAIL_BYNSD_SUCCESS,
  payload: noteScheduleDetail,
})

export const getNoteScheduleDetailbyNSDFail = error => ({
  type: GET_NOTESCHEDULE_DETAIL_BYNSD_FAIL,
  payload: error,
})

export const deleteNoteSchedule = noteScheduleID => ({
  type: DELETE_NOTE_SCHEDULE,
  payload: noteScheduleID,
})

export const deleteNoteScheduleSuccess = noteScheduleID => ({
  type: DELETE_NOTE_SCHEDULE_SUCCESS,
  payload: noteScheduleID,
})

export const deleteNoteScheduleFail = error => ({
  type: DELETE_NOTE_SCHEDULE_FAIL,
  payload: error,
})



export const deleteNoteScheduleDetail = noteScheduleDetailID => ({
  type: DELETE_NOTE_SCHEDULE_DETAIL,
  payload: noteScheduleDetailID,
})

export const deleteNoteScheduleDetailSuccess = noteScheduleDetailID => ({
  type: DELETE_NOTE_SCHEDULE_DETAIL_SUCCESS,
  payload: noteScheduleDetailID,
})

export const deleteNoteScheduleDetailFail = error => ({
  type: DELETE_NOTE_SCHEDULE_DETAIL_FAIL,
  payload: error,
})
