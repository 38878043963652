import React, { useState, useEffect } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import {
  Button,
  Card,
  CardBody,
  CardText,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Alert,
} from "reactstrap"
import classnames from "classnames"
import BasicInfoTab from "./tabs/BasicInfoTab"
// import MediaAlbumTab from "./tabs/MediaAlbumTab"

//import { getActivities as onGetActivities} from "store/actions"
import { isEmpty } from "lodash"
import EmptyContainer from "components/Common/EmptyContainer"
import { useDeepCompareEffect } from "hooks"
import WeeklyTimeTable from "./pages/WeeklyTimeTable"
import {   getLessons as onGetLessons } from "store/actions"

const LessonPlanAddEdit = () => {
  const navigate = useNavigate()
  const routeParams = useParams()
  const dispatch = useDispatch()
  const [header, setHeader] = useState("")
  const [tabValue, setTabValue] = useState("1")
  const [objCurr, setObjCurr] = useState(null)

  const { lessons, loading } = useSelector(state => state.lesson)
  //const [isLoading, setLoading] = useState(loading)

  useDeepCompareEffect(() => {
    dispatch(onGetLessons())
    const { id } = routeParams
    let obj = lessons?.find(x => x.RowID == id)
    if (id === "new") {
      setHeader("Add Lesson Plan")
      setObjCurr({})
    } else {
      setHeader("Edit Lesson Plan")
      setObjCurr(obj)
    }
  }, [dispatch, lessons, objCurr, routeParams])

  //////////////////Curriculums STATE/////////////////////

  const toggleTab = tab => {
    if (tabValue !== tab) {
      setTabValue(tab)
    }
  }
  const NavItemTab = (navTitle, navValue) => {
    return (
      <NavItem>
        <NavLink
          style={{ cursor: "pointer" }}
          className={classnames({
            active: tabValue === navValue,
          })}
          onClick={() => {
            toggleTab(navValue)
          }}
        >
          {navTitle}
        </NavLink>
      </NavItem>
    )
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Lesson Plan" breadcrumbItem={header} />
          {routeParams.id !== "new" && isEmpty(objCurr) ? (
            <EmptyContainer
              backURL="/learning/lesson-plans"
              message="There is no such lesson plan!"
              linkText="Go to Lesson Plan Page"
            />
          ) : (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    {/* <CardTitle>Basic Information</CardTitle> */}
                    {/* <p className="card-title-desc mb-4">
                      Fill all information below
                    </p>
                    <Row>
                      <Col sm="12">
                        <BasicInfoTab curriculum={...objCurr} />
                      </Col>
                    </Row> */}

                    {/* <CardTitle>Basic Information</CardTitle>
                    <p className="card-title-desc mb-4">
                      Fill all information below
                    </p> */}
                    {routeParams.id !== "new" ? (
                      <Nav tabs className="nav-tabs-custom nav-justified">
                        {NavItemTab("Basic Info", "1")}
                        {NavItemTab("Schedule", "2")}
                      </Nav>
                    ) : (
                      ""
                    )}
                    <TabContent activeTab={tabValue} className="p-3 text-muted">
                      <TabPane tabId="1">
                        <Row>
                          <Col sm="12">
                            <BasicInfoTab lesson={objCurr} />
                          </Col>
                        </Row>
                      </TabPane>

                      <TabPane tabId="2">
                        <Row>
                          <Col sm="12">
                            <WeeklyTimeTable lesson={objCurr} />
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default LessonPlanAddEdit
