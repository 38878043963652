import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
//controls
import { Col, Label, Row, Input, FormFeedback, InputGroup } from "reactstrap"
import Flatpickr from "react-flatpickr"

//react form
import {
  FormProvider,
  useForm,
  Controller,
  useFieldArray,
} from "react-hook-form"
import Select from "react-select"
import { createSelector } from "reselect"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

//custom hooks
import { useDeepCompareEffect } from "hooks"

import Switch from "react-switch"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"

import { isEmpty } from "lodash"

import {
  addLesson as onAddLesson,
  updateLesson as onUpdateLesson,
  getPrograms as onGetPrograms,
} from "store/actions"
import PageModal from "components/Common/PageModal"
import Moment from "moment"

const formdefault = {
  LessonID: 0,
  Name: "",
  WeekDate: "",
  IsShared: false,
}

const LessonPlanModal = ({ show, onEdit, lesson, onCloseClick }) => {
  const dispatch = useDispatch()
  // const [types, setTypes] = useState([])
  //const [scheduleArray, setScheduleArray] = useState([])
  //const { addon } = useSelector(state => state.addon)

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    LessonID: yup.number(),
    Name: yup.string().required("Required"),
    Programs: yup.object().required("Required"),
    WeekDate: yup.string().required("Required"),
    IsShared: yup.bool(),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: formdefault,
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, setValue, getValues, watch, trigger } =
    methods
  const { errors, isValid } = formState

  //////////////////STAFF SCHEDULE STATE/////////////////////
  const { programs } = useSelector(state => state.program)

  useDeepCompareEffect(() => {
    function updateState() {
     dispatch(onGetPrograms())
    }

    updateState()
  }, [dispatch])

  useEffect(() => {
    if (onEdit) {
      const response = {
        ...lesson,
        Programs: {
          ProgramID: Number(lesson?.ProgramID),
          Name: lesson?.ProgramName,
        },
        WeekDate: lesson?.WeekDate
        ? Moment(lesson?.WeekDate).format("DD MMM, YYYY")
        : "",
      }
      reset(response)
    } else {
      //setScheduleArray([])
      reset(formdefault)
    }
  }, [lesson, onEdit])

  const onSaveClick = () => {
    trigger()
    if (isValid) {
      //console.log(getValues())
      if (!onEdit) {
        dispatch(onAddLesson(getValues()))
      } else {
        dispatch(onUpdateLesson(getValues()))
      }
      onCloseClick()
    }
  }

  const onClosed = () => {
    reset(formdefault)
  }

  function getDifference(array1, array2) {
    return array1.filter(o1 => {
      return !array2.some(o2 => {
        return o1 === o2.DayID
      })
    })
  }

  return (
    <PageModal
      show={show}
      onCloseClick={onCloseClick}
      onSaveClick={() => onSaveClick()}
      onClosed={() => onClosed()}
      header={onEdit ? "Edit Lesson Plan": "Add Lesson Plan"}
      size="lg"
    >
      <FormProvider {...methods}>
        <Row>
          <Col lg={12}>
            <div className="mb-3">
              <Label>Name</Label>
              <Controller
                name="Name"
                control={control}
                render={({ field }) => (
                  <>
                    <Input
                      {...field}
                      id="Name"
                      type="text"
                      required
                      invalid={!!errors.Name}
                    />
                    {errors?.Name?.message ? (
                      <FormFeedback type="invalid" className="d-block">
                        {errors?.Name?.message}
                      </FormFeedback>
                    ) : null}
                  </>
                )}
              />
            </div>
            <div className="mb-3">
              <Label>Program</Label>
              <Controller
                name="Programs"
                control={control}
                render={({ field }) => (
                  <>
                    <Select
                      {...field}
                      id="Programs"
                      options={programs}
                      getOptionLabel={option => option.Name}
                      getOptionValue={option => option.ProgramID}
                      required
                      aria-invalid={!!errors.Programs}
                      classNamePrefix="select2-selection"
                    />
                    {errors?.Programs?.message ? (
                      <FormFeedback type="invalid" className="d-block">
                        {errors?.Programs?.message}
                      </FormFeedback>
                    ) : null}
                  </>
                )}
              />
            </div>

            <div className="mb-3">
              <Label>Week Date</Label>
              <Controller
                name="WeekDate"
                control={control}
                render={({ field }) => (
                  <>
                    <Flatpickr
                      {...field}
                      className="form-control d-block"
                      id="WeekDate"
                      options={{
                        dateFormat: "d M, Y",
                      }}
                      onChange={(selectedDates, dateStr, instance) => {
                        setValue("WeekDate", dateStr)
                      }}
                      required
                    />
                    {errors?.WeekDate?.message ? (
                      <FormFeedback type="invalid" className="d-block">
                        {errors?.WeekDate?.message}
                      </FormFeedback>
                    ) : null}
                  </>
                )}
              />
            </div>
            <div className="mb-3">
              <Label>Shared with Parent</Label>
              <div>
                <Controller
                  name="IsShared"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Switch
                        {...field}
                        id="IsShared"
                        checked={field.value}
                        uncheckedIcon={<Offsymbol />}
                        checkedIcon={<OnSymbol />}
                        className="me-1 mb-sm-8 mb-2"
                        onColor="#626ed4"
                      />
                    </>
                  )}
                />
              </div>
            </div>
          </Col>
         

        </Row>
      </FormProvider>
    </PageModal>
  )
}

LessonPlanModal.propTypes = {
  onCloseClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  show: PropTypes.any,
}

export default LessonPlanModal
