export const EnumObjectTypes = {
  Staff: 1,
  Adult: 2,
  Children: 3,
  Class: 4,
  CenterAdmin: 5,
}

export const EnumRoleTypes = {
  Center_Director: 1,
  Assistant_Director: 2,
  Center_Admin: 3,
  Accountant: 4,
  Teaching_Staff: 5,
  Non_Teaching_Staff: 6,
  Human_Resources: 7,
  Kitchen_Manager: 8,
  Curriculum_Manager: 9,
  Time_Only: 10,
  Senior_Manager: 11,
  Center_Owner: 12,
}
