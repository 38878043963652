import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
//controls
import { Col, Label, Row, Input, FormFeedback, InputGroup } from "reactstrap"
import Flatpickr from "react-flatpickr"

//react form
import {
  FormProvider,
  useForm,
  Controller,
  useFieldArray,
} from "react-hook-form"
import Select from "react-select"
import { createSelector } from "reselect"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

//custom hooks
import { useDeepCompareEffect } from "hooks"

import Switch from "react-switch"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"

import { isEmpty } from "lodash"
import PageModal from "components/Common/PageModal"
import moment from "moment"

import {
  addCurriculumSchedule as onAddCurriculumSchedule,
  updateCurriculumScheduleDetail as onUpdateCurriculumScheduleDetail,
  getNoteCategories as onGetNoteCategories,
  getCurriculumScheduleDetailbyCSD as onGetCurriculumScheduleDetailbyCSD,
} from "store/actions"

const formdefault = {
  CurriculumScheduleID: 0,
  Description: "",
}

const NoteModal = ({
  show,
  onEdit,
  selectedDay,
  selectedTime,
  curriculumscheduleID,
  curriculum,
  onCloseClick,
  header,
}) => {
  const dispatch = useDispatch()
  // const [types, setTypes] = useState([])
  //const [scheduleArray, setScheduleArray] = useState([])
  //const { curriculum } = useSelector(state => state.curriculum)

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    CurriculumScheduleID: yup.number(),
    NoteCategories: yup.object().required("Required"),
    Description: yup.string().required("Required"),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: formdefault,
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, setValue, getValues, watch, trigger } =
    methods
  const { errors, isValid } = formState
  const { notecategories, curriculumscheduleDetails } = useSelector(
    state => state.curriculum
  )

  useDeepCompareEffect(() => {
    function updateState() {
      dispatch(onGetNoteCategories())
      const obj = {
        CurriculumScheduleID: curriculumscheduleID,
        Day: selectedDay,
      }
      debugger
      dispatch(onGetCurriculumScheduleDetailbyCSD({ ...obj }))
    }

    updateState()
  }, [dispatch, curriculumscheduleID, selectedDay, curriculumscheduleDetails])

  useEffect(() => {
    if (onEdit && curriculumscheduleDetails && curriculumscheduleDetails.length>0) {
      debugger
       const response = {
        CurriculumScheduleID: Number(
          curriculumscheduleDetails[0]?.CurriculumScheduleID
        ),
        NoteCategories: {
          NoteCategoryID: Number(
            curriculumscheduleDetails[0]?.NoteCategoryID
          ),
          NoteCategory: curriculumscheduleDetails[0]?.NoteCategory,
        },
        Description: curriculumscheduleDetails[0]?.Description,
      }
      reset(response)
    } else {
     
      reset(formdefault)

     // reset()
    }
  }, [curriculum, onEdit, curriculumscheduleDetails])

  const onSaveClick = () => {
    trigger()
    if (isValid) {
     
      if (curriculumscheduleDetails && curriculumscheduleDetails.length==0) {
        // add new
        dispatch(
          onAddCurriculumSchedule({
            ...getValues(),
            Time: selectedTime,
            Day: selectedDay,
            CurriculumID: curriculum.CurriculumID,
            CurriculumScheduleID: curriculumscheduleID,
            CurriculumScheduleDetailID:0
           
          })
        )
        
      } else {
        // update
        dispatch(
          onUpdateCurriculumScheduleDetail({
            ...getValues(),
            Time: selectedTime,
            Day: selectedDay,
            CurriculumID: curriculum.CurriculumID,
            CurriculumScheduleID: curriculumscheduleID,
            CurriculumScheduleDetailID: Number(
              curriculumscheduleDetails[0]?.CurriculumScheduleDetailID
            )
          })
        )
      }
      onCloseClick()
    }
  }

  const onClosed = () => {
    reset(formdefault)
  }

  return (
    <PageModal
      show={show}
      onCloseClick={onCloseClick}
      onSaveClick={() => onSaveClick()}
      onClosed={() => onClosed()}
      header={(curriculumscheduleDetails && curriculumscheduleDetails.length==0)? 'Add Note': 'Update Note'}
    >
      <FormProvider {...methods}>
        <Row>
          <Col lg={6}>
            <div className="mb-6">
              <Label>Time: </Label> {selectedTime}
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-6">
              <Label>Day: </Label> {selectedDay}
            </div>
          </Col>
          <Col lg={12}></Col>
          <Col lg={12}>
            <div className="mb-3">
              <Label>Category</Label>
              <Controller
                name="NoteCategories"
                control={control}
                render={({ field }) => (
                  <>
                    <Select
                      {...field}
                      id="NoteCategories"
                      options={notecategories}
                      getOptionLabel={option => option.NoteCategory}
                      getOptionValue={option => option.NoteCategoryID}
                      required
                      aria-invalid={!!errors.NoteCategories}
                      classNamePrefix="select2-selection"
                    />
                    {errors?.NoteCategories?.message ? (
                      <FormFeedback type="invalid" className="d-block">
                        {errors?.NoteCategories?.message}
                      </FormFeedback>
                    ) : null}
                  </>
                )}
              />
            </div>
            <div className="mb-3">
              <Label>Description</Label>
              <Controller
                name="Description"
                control={control}
                render={({ field }) => (
                  <>
                    <Input
                      {...field}
                      id="Description"
                      type="textarea"
                      required
                      invalid={!!errors.Description}
                    />
                    {errors?.Description?.message ? (
                      <FormFeedback type="invalid" className="d-block">
                        {errors?.Description?.message}
                      </FormFeedback>
                    ) : null}
                  </>
                )}
              />
            </div>
          </Col>
        </Row>
      </FormProvider>
    </PageModal>
  )
}

NoteModal.propTypes = {
  onCloseClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  show: PropTypes.any,
}

export default NoteModal
