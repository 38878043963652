import {
  GET_LESSONS_FAIL,
  GET_LESSONS_SUCCESS,
  DELETE_LESSON_SUCCESS,
  DELETE_LESSON_FAIL,
  ADD_LESSON_FAIL,
  ADD_LESSON_SUCCESS,
  DELETE_LESSON_SCHEDULE_SUCCESS,
  DELETE_LESSON_SCHEDULE_FAIL,
  GET_LESSON_DETAIL_SUCCESS,
  GET_LESSON_DETAIL_FAIL,
  UPDATE_LESSON_SUCCESS,
  UPDATE_LESSON_FAIL,
  RESET_LESSON_SUCCESS,
  GET_NOTECATEGORIES_FAIL,
  GET_NOTECATEGORIES_SUCCESS,
  ADD_LESSON_SCHEDULE_FAIL,
  ADD_LESSON_SCHEDULE_SUCCESS,
  GET_LESSONSCHEDULES_BY_LESSON_SUCCESS,
  GET_LESSONSCHEDULES_BY_LESSON_FAIL,
  UPDATE_LESSONSCHEDULE_DETAIL_SUCCESS,
  UPDATE_LESSONSCHEDULE_DETAIL_FAIL,
  GET_LESSONSCHEDULE_DETAIL_BYLSD_SUCCESS,
  GET_LESSONSCHEDULE_DETAIL_BYLSD_FAIL,
  DELETE_LESSON_SCHEDULE_DETAIL_SUCCESS,
  DELETE_LESSON_SCHEDULE_DETAIL_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  lessons: [],
  lesson: {},
  notecategories: [],
  lessonschedules: [],
  lessonscheduleDetails: [],
  error: {},
  loading: true,
}

const Lesson = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LESSONS_SUCCESS:
      return {
        ...state,
        lessons: action.payload,
        loading: true,
      }
    case GET_LESSONS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_LESSON_SUCCESS: {
      const data = {
        ...state,
        lessons: state.lessons.filter(
          lesson => lesson.LessonID !== parseInt(action.payload)
        ),
      }
      return {
        ...state,
        lessons: state.lessons.filter(
          lesson => lesson.LessonID !== parseInt(action.payload)
        ),
      }
    }
    case DELETE_LESSON_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_LESSON_SUCCESS:
      return {
        ...state,
        lessons: [...state.lessons, action.payload],
      }
    case ADD_LESSON_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_LESSON_DETAIL_SUCCESS:
      return {
        ...state,
        lesson: action.payload,
        loading: true,
      }

    case GET_LESSON_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_LESSON_SUCCESS:
      return {
        ...state,
        lessons: state.lessons.map(lesson =>
          lesson.LessonID.toString() === action.payload.LessonID.toString()
            ? { lesson, ...action.payload }
            : lesson
        ),
      }
    case UPDATE_LESSON_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case RESET_LESSON_SUCCESS:
      return {
        ...state,
        lesson: action.payload,
      }
    case GET_NOTECATEGORIES_SUCCESS:
      return {
        ...state,
        notecategories: action.payload,
        loading: true,
      }
    case GET_NOTECATEGORIES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_LESSON_SCHEDULE_SUCCESS:
      return {
        ...state,
        lessonschedules: [...state.lessonschedules, action.payload],
      }
    case ADD_LESSON_SCHEDULE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_LESSONSCHEDULES_BY_LESSON_SUCCESS:
      return {
        ...state,
        lessonschedules: action.payload,
        loading: true,
      }
    case GET_LESSONSCHEDULES_BY_LESSON_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_LESSONSCHEDULE_DETAIL_SUCCESS:
      return {
        lessonschedules: [
          ...state.lessonschedules.map(lessonschedule =>
            lessonschedule.LessonScheduleID.toString() ===
            action.payload.LessonScheduleID.toString()
              ? { lessonschedule, ...action.payload }
              : lessonschedule
          ),
        ],
      }
    case UPDATE_LESSONSCHEDULE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_LESSONSCHEDULE_DETAIL_BYLSD_SUCCESS:
      return {
        ...state,
        lessonscheduleDetails: action.payload,
        loading: true,
      }
    case GET_LESSONSCHEDULE_DETAIL_BYLSD_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_LESSON_SCHEDULE_SUCCESS: {
      return {
        ...state,
        lessonschedules: state.lessonschedules.filter(
          lessonschedule =>
            lessonschedule.LessonScheduleID !== parseInt(action.payload)
        ),
      }
    }
    case DELETE_LESSON_SCHEDULE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_LESSON_SCHEDULE_DETAIL_SUCCESS: {
      return {
        ...state,
        lessonscheduleDetails: state.lessonscheduleDetails.filter(
          lessonscheduledetail =>
            lessonscheduledetail.LessonScheduleDetailID !==
            parseInt(action.payload)
        ),
      }
    }
    case DELETE_LESSON_SCHEDULE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default Lesson
