import React, { useState, useEffect } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"
import StackedColumnChart from "./StackedColumnChart"
import classNames from "classnames"

const Accounting = () => {
  const yearData = [
    {
      name: "Charges",
      data: [44, 55, 41, 67, 22, 43, 36, 52, 24, 18, 36, 48],
    },
    {
      name: "Payments",
      data: [13, 23, 20, 8, 13, 27, 18, 22, 10, 16, 24, 22],
    },
  ]

  const monthData = [
    {
      name: "Charges",
      data: [34, 55, 21, 77, 32, 63, 86, 42, 34, 18, 16, 41],
    },
    {
      name: "Payments",
      data: [10, 63, 40, 80, 52, 41, 11, 32, 30, 86, 44, 33],
    },
  ]

  const weekData = [
    {
      name: "Charges",
      data: [14, 52, 11, 57, 22, 33, 31, 22, 64, 14, 32, 68],
    },
    {
      name: "Payments",
      data: [13, 23, 20, 8, 13, 27, 18, 22, 10, 16, 24, 22],
    },
  ]

  const [periodData, setPeriodData] = useState([])
  const [periodType, setPeriodType] = useState("yearly")

  const onChangeChartPeriod = pType => {
    setPeriodType(pType)
  }

  useEffect(() => {
    if (periodType == "yearly") {
      setPeriodData(yearData)
    } else if (periodType == "weekly") {
      setPeriodData(weekData)
    } else if (periodType == "monthly") {
      setPeriodData(monthData)
    }
  }, [periodType])

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <div className="d-sm-flex flex-wrap">
            <h4 className="card-title mb-4">Accounting Chart</h4>
            <div className="ms-auto">
              <ul className="nav nav-pills">
                <li className="nav-item">
                  <Link
                    to="#"
                    className={classNames(
                      { active: periodType === "weekly" },
                      "nav-link"
                    )}
                    onClick={() => {
                      onChangeChartPeriod("weekly")
                    }}
                    id="one_month"
                  >
                    Week
                  </Link>{" "}
                </li>
                <li className="nav-item">
                  <Link
                    to="#"
                    className={classNames(
                      { active: periodType === "monthly" },
                      "nav-link"
                    )}
                    onClick={() => {
                      onChangeChartPeriod("monthly")
                    }}
                    id="one_month"
                  >
                    Month
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="#"
                    className={classNames(
                      { active: periodType === "yearly" },
                      "nav-link"
                    )}
                    onClick={() => {
                      onChangeChartPeriod("yearly")
                    }}
                    id="one_month"
                  >
                    Year
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <StackedColumnChart
            periodData={periodData}
            dataColors='["--bs-primary", "--bs-warning", "--bs-success"]'
          />
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default Accounting
