import { call, put, takeEvery } from "redux-saga/effects"

// Curriculum Redux States
import {
  GET_CURRICULUMS,
  DELETE_CURRICULUM,
  ADD_CURRICULUM,
  GET_CURRICULUM_DETAIL,
  UPDATE_CURRICULUM,
  GET_NOTECATEGORIES,
  RESET_CURRICULUM,
  ADD_CURRICULUM_SCHEDULE,
  GET_CURRICULUMSHEDULES_BY_CURRICULUM,
  UPDATE_CURRICULUMSHEDULE_DETAIL,
  GET_CURRICULUMSHEDULE_DETAIL_BYCSD,
  DELETE_CURRICULUM_SCHEDULE,
  DELETE_CURRICULUM_SCHEDULE_DETAIL,
} from "./actionTypes"
import {
  getCurriculumsSuccess,
  getCurriculumsFail,
  deleteCurriculumSuccess,
  deleteCurriculumFail,
  addCurriculumSuccess,
  addCurriculumFail,
  getCurriculumDetailSuccess,
  getCurriculumDetailFail,
  updateCurriculumSuccess,
  updateCurriculumFail,
  resetCurriculumSuccess,
  getNoteCategoriesSuccess,
  getNoteCategoriesFail,
  addCurriculumScheduleSuccess,
  addCurriculumScheduleFail,
  getCurriculumSchedulesByCurriculumSuccess,
  getCurriculumSchedulesByCurriculumFail,
  updateCurriculumScheduleDetailSuccess,
  updateCurriculumScheduleDetailFail,
  getCurriculumScheduleDetailbyCSDSuccess,
  getCurriculumScheduleDetailbyCSDFail,
  deleteCurriculumScheduleSuccess,
  deleteCurriculumScheduleFail,
  deleteCurriculumScheduleDetailSuccess,
  deleteCurriculumScheduleDetailFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getCurriculums,
  deleteCurriculum,
  addNewCurriculum,
  getCurriculumDetail,
  updateCurriculum,
  getNoteCategories,
  addCurriculumSchedule,
  deleteCurriculumSchedule,
  getCurriculumSchedulesByCurriculum,
  updateCurriculumScheduleDetail,
  getCurriculumScheduleDetailbyCSD,
  deleteCurriculumScheduleDetail,
} from "helpers/backend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Curriculum from "./reducer"

function* fetchCurriculums() {
  try {
    const response = yield call(getCurriculums)
    yield put(getCurriculumsSuccess(response))
  } catch (error) {
    yield put(getCurriculumsFail(error))
  }
}

function* onDeleteCurriculum({ payload: CurriculumID }) {
  try {
    const response = yield call(deleteCurriculum, CurriculumID)
    yield put(deleteCurriculumSuccess(response))
    toast.success("Curriculum deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteCurriculumFail(error))
    toast.error("Curriculum deletion failed", { autoClose: 2000 })
  }
}

function* onAddCurriculum({ payload: curriculum }) {
  try {
    const curriculumNew = {
      ...curriculum,
      ProgramID: curriculum.Programs?.ProgramID
        ? curriculum.Programs?.ProgramID
        : 0,
    }
    const response = yield call(addNewCurriculum, curriculumNew)
    yield put(addCurriculumSuccess(response[0]))
    toast.success("Curriculum added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addCurriculumFail(error))
    toast.error("Curriculum addition failed", { autoClose: 2000 })
  }
}

function* fetchCurriculumDetail({ RowID }) {
  try {
    const response = yield call(getCurriculumDetail, RowID)
    let result = response[0]
    result = {
      ...result,
    }
    yield put(getCurriculumDetailSuccess(result))
  } catch (error) {
    yield put(getCurriculumDetailFail(error))
  }
}

function* onUpdateCurriculum({ payload: curriculum }) {
  try {
    const curriculumNew = {
      ...curriculum,
      ProgramID: curriculum.Programs?.ProgramID
        ? curriculum.Programs?.ProgramID
        : 0,
    }
    const response = yield call(updateCurriculum, curriculumNew)
    let result = response[0]

    yield put(updateCurriculumSuccess(result))
    toast.success("Curriculum updated successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateCurriculumFail(error))
    toast.error("Curriculum updation failed", { autoClose: 2000 })
  }
}

function* fetchNoteCategories() {
  try {
    const response = yield call(getNoteCategories)
    yield put(getNoteCategoriesSuccess(response))
  } catch (error) {
    yield put(getNoteCategoriesFail(error))
  }
}

function* onResetCurriculum() {
  try {
    yield put(resetCurriculumSuccess({}))
  } catch (error) {}
}

function* onAddCurriculumSchedule({ payload: curriculumschedule }) {
  try {
    debugger
    const curriculumscheduleNew = {
      ...curriculumschedule,
      NoteCategoryID: curriculumschedule.NoteCategories?.NoteCategoryID
        ? curriculumschedule.NoteCategories?.NoteCategoryID
        : 0,
    }

    const response = yield call(addCurriculumSchedule, curriculumscheduleNew)
    yield put(addCurriculumScheduleSuccess(response[0]))
    toast.success("Curriculum Schedule added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addCurriculumScheduleFail(error))
    toast.error("Curriculum Schedule addition failed", { autoClose: 2000 })
  }
}
function* fetchCurriculumSchedulesByCurriculum({ RowID }) {
  try {
    const response = yield call(getCurriculumSchedulesByCurriculum, RowID)
    yield put(getCurriculumSchedulesByCurriculumSuccess(response))
  } catch (error) {
    yield put(getCurriculumSchedulesByCurriculumFail(error))
  }
}
function* onUpdateCurriculumScheduleDetail({
  payload: curriculumScheduleDetail,
}) {
  try {
    
    const curriculumScheduleDetailNew = {
      ...curriculumScheduleDetail,
      NoteCategoryID: curriculumScheduleDetail.NoteCategories?.NoteCategoryID
        ? curriculumScheduleDetail.NoteCategories?.NoteCategoryID
        : 0,
      // CurriculumScheduleDetailID:
      //   curriculumScheduleDetail?.CurriculumScheduleDetailID,
      // CurriculumScheduleID: curriculumScheduleDetail?.CurriculumScheduleID,
    }

    const response = yield call(
      updateCurriculumScheduleDetail,
      curriculumScheduleDetailNew
    )
    let result = response[0]
    
    yield put(updateCurriculumScheduleDetailSuccess(result))
    toast.success("Curriculum Schedule detail updated successfully", {
      autoClose: 2000,
    })
  } catch (error) {
    yield put(updateCurriculumScheduleDetailFail(error))
    toast.error("Curriculum Schedule detail updation failed", {
      autoClose: 2000,
    })
  }
}

function* fetchCurriculumScheduleDetailsByCSD({
  payload: curriculumScheduleDetail,
}) {
  try {
    const response = yield call(
      getCurriculumScheduleDetailbyCSD,
      curriculumScheduleDetail
    )
    yield put(getCurriculumScheduleDetailbyCSDSuccess(response))
  } catch (error) {
    yield put(getCurriculumScheduleDetailbyCSDFail(error))
  }
}

function* onDeleteCurriculumSchedule({ payload: CurriculumScheduleID }) {
  try {
    const response = yield call(deleteCurriculumSchedule, CurriculumScheduleID)
    yield put(deleteCurriculumScheduleSuccess(response))
    toast.success("Curriculum schedule deleted successfully", {
      autoClose: 2000,
    })
  } catch (error) {
    yield put(deleteCurriculumScheduleFail(error))
    toast.error("Curriculum schedule deletion failed", { autoClose: 2000 })
  }
}

function* onDeleteCurriculumScheduleDetail({
  payload: CurriculumScheduleDetailID,
}) {
  try {
    const response = yield call(
      deleteCurriculumScheduleDetail,
      CurriculumScheduleDetailID
    )
    yield put(deleteCurriculumScheduleDetailSuccess(response))
    toast.success("Curriculum schedule detail deleted successfully", {
      autoClose: 2000,
    })
  } catch (error) {
    yield put(deleteCurriculumScheduleDetailFail(error))
    toast.error("Curriculum schedule detail deletion failed", {
      autoClose: 2000,
    })
  }
}


function* curriculumSaga() {
  yield takeEvery(GET_CURRICULUMS, fetchCurriculums)
  yield takeEvery(DELETE_CURRICULUM, onDeleteCurriculum)
  yield takeEvery(ADD_CURRICULUM, onAddCurriculum)
  yield takeEvery(GET_CURRICULUM_DETAIL, fetchCurriculumDetail)
  yield takeEvery(UPDATE_CURRICULUM, onUpdateCurriculum)
  yield takeEvery(GET_NOTECATEGORIES, fetchNoteCategories)
  yield takeEvery(RESET_CURRICULUM, onResetCurriculum)
  yield takeEvery(ADD_CURRICULUM_SCHEDULE, onAddCurriculumSchedule)
  yield takeEvery(
    GET_CURRICULUMSHEDULES_BY_CURRICULUM,
    fetchCurriculumSchedulesByCurriculum
  )
  yield takeEvery(
    UPDATE_CURRICULUMSHEDULE_DETAIL,
    onUpdateCurriculumScheduleDetail
  )
  yield takeEvery(
    GET_CURRICULUMSHEDULE_DETAIL_BYCSD,
    fetchCurriculumScheduleDetailsByCSD
  )
  yield takeEvery(DELETE_CURRICULUM_SCHEDULE, onDeleteCurriculumSchedule)
  yield takeEvery(
    DELETE_CURRICULUM_SCHEDULE_DETAIL,
    onDeleteCurriculumScheduleDetail
  )
}

export default curriculumSaga
