import { call, put, takeEvery } from "redux-saga/effects"
import Moment from "moment"

// Adult Redux States
import {
  GET_ADULTS,
  DELETE_ADULT,
  ADD_NEW_ADULT,
  GET_ADULT_DETAIL,
  UPDATE_ADULT,
  UPDATE_ADULT_PHOTO,
  RESET_ADULT,
  GET_ADULT_RELATIONS,
  UPDATE_ADULT_NOTES,
  ADD_CHILD_BY_ADULT,
} from "./actionTypes"
import {
  getAdultsFail,
  getAdultsSuccess,
  deleteAdultSuccess,
  deleteAdultFail,
  addAdultSuccess,
  addAdultFail,
  getAdultDetailSuccess,
  getAdultDetailFail,
  updateAdultSuccess,
  updateAdultFail,
  updateAdultPhotoSuccess,
  updateAdultPhotoFail,
  resetAdultSuccess,
  getAdultRelationsSuccess,
  getAdultRelationsFail,
  updateAdultNotesSuccess,
  updateAdultNotesFail,
  addChildByAdultSuccess,
  addChildByAdultFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getAdults,
  deleteAdult,
  addNewAdult,
  getAdultDetail,
  updateAdult,
  updateAdultPhoto,
  getAdultRelations,
  updateAdultNotes,
  addChildByAdult,
} from "helpers/backend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {
  getCountryByID,
  getStateByID,
  getCityByID,
} from "../../helpers/location_helper"
function* fetchAdults() {
  try {
    const response = yield call(getAdults)
    yield put(getAdultsSuccess(response))
  } catch (error) {
    yield put(getAdultsFail(error))
  }
}

function* onDeleteAdult({ payload: adult }) {
  try {
    const response = yield call(deleteAdult, adult)
    yield put(deleteAdultSuccess(response))
    toast.success("Adult deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteAdultFail(error))
    toast.error("Adult deletion failed", { autoClose: 2000 })
  }
}

function* onAddNewAdult({ payload: adult }) {
  try {
    var photoData = new FormData()
    photoData.append("files", adult.Photo)
    photoData.append("field", "Adult")
    delete adult.Photo
    const adult1 = {
      ...adult,
      CountryID: adult.Countries.id,
      StateID: adult.States.id,
      CityID: adult.Cities.id,
      GenderID: adult.Genders.GenderID,
    }
    const response = yield call(addNewAdult, adult1)
    yield put(addAdultSuccess(response[0]))
    photoData.append("refId", response[0].AdultID)
    try {
      const response1 = yield call(updateAdultPhoto, photoData)
      //let result = response[0]

      yield put(updateAdultPhotoSuccess(response1))
      //toast.success("Photo Update Successfully", { autoClose: 2000 })
    } catch (error) {
      yield put(updateAdultPhotoFail(error))
      toast.error("Photo Update Failed", { autoClose: 2000 })
    }
    toast.success("Adult added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addAdultFail(error))
    toast.error("Adult addition failed", { autoClose: 2000 })
  }
}

function* fetchAdultDetail({ adultId }) {
  try {
    debugger
    const response = yield call(getAdultDetail, adultId)
    let result = response[0]

    let country = getCountryByID(result.CountryID)
    let state = getStateByID(result.StateID)
    let city = getCityByID(result.CityID)
    result = {
      ...result,
      Countries: {
        id: Number(country.id),
        name: country.name,
      },
      States: {
        id: Number(state.id),
        name: state.name,
      },
      Cities: {
        id: Number(city.id),
        name: city.name,
      },
      ZipCode: result.ZipCode ?? "",
      Address: result.Address ?? "",
      Genders: {
        GenderID: result.GenderID ?? "",
        Gender: result.Gender ?? "Select...",
      },
    }
    yield put(getAdultDetailSuccess(result))
  } catch (error) {
    yield put(getAdultDetailFail(error))
  }
}

function* onUpdateAdult({ payload: adult }) {
  try {
    delete adult.Photo
    const adult1 = {
      ...adult,
      CountryID: adult.Countries.id,
      StateID: adult.States.id,
      CityID: adult.Cities.id,
      GenderID: adult.Genders.GenderID,
    }
    const response = yield call(updateAdult, adult1)
    let result = response[0]

    yield put(updateAdultSuccess(result))
    toast.success("Adult updated successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateAdultFail(error))
    toast.error("Adult updation failed", { autoClose: 2000 })
  }
}
function* onUpdateAdultPhoto({ payload: adult }) {
  try {
    var photoData = new FormData()
    photoData.append("files", adult.Photo)
    photoData.append("refId", adult.AdultID)
    photoData.append("field", "Adult")
    const response = yield call(updateAdultPhoto, photoData)
    //let result = response[0]

    yield put(updateAdultPhotoSuccess(response))
    //toast.success("Photo Update Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateAdultPhotoFail(error))
    toast.error("Photo Update Failed", { autoClose: 2000 })
  }
}

function* fetchAdultRelations({ payload: adultId }) {
  try {
    const response = yield call(getAdultRelations, adultId)
    yield put(getAdultRelationsSuccess(response))
  } catch (error) {
    yield put(getAdultRelationsFail(error))
  }
}

function* onUpdateAdultNotes({ payload: { adultID, obj } }) {
  try {
    const request = {
      AdultID: adultID,
      Notes: obj.Notes,
    }
    const response = yield call(updateAdultNotes, request)
    yield put(updateAdultNotesSuccess(response))
  } catch (error) {
    yield put(updateAdultNotesFail(error))
  }
}

function* onAddChildByAdult({ payload: child }) {
  try {
    const request = {
      ...child,
      GenderID: child.Genders.GenderID,
      Classes: {
        ClassID: 0,
      },
      Adults: {
        AdultID: child.AdultID,
      },
    }
    const response = yield call(addChildByAdult, request)
    yield put(addChildByAdultSuccess(response))
    toast.success("Child added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addChildByAdultFail(error))
    toast.error("Child addition failed", { autoClose: 2000 })
  }
}

function* onResetAdult() {
  try {
    yield put(resetAdultSuccess({}))
  } catch (error) {}
}
function* adultSaga() {
  yield takeEvery(GET_ADULTS, fetchAdults)
  yield takeEvery(DELETE_ADULT, onDeleteAdult)
  yield takeEvery(ADD_NEW_ADULT, onAddNewAdult)
  yield takeEvery(GET_ADULT_DETAIL, fetchAdultDetail)
  yield takeEvery(UPDATE_ADULT, onUpdateAdult)
  yield takeEvery(UPDATE_ADULT_PHOTO, onUpdateAdultPhoto)
  yield takeEvery(RESET_ADULT, onResetAdult)
  yield takeEvery(GET_ADULT_RELATIONS, fetchAdultRelations)
  yield takeEvery(UPDATE_ADULT_NOTES, onUpdateAdultNotes)
  yield takeEvery(ADD_CHILD_BY_ADULT, onAddChildByAdult)
}

export default adultSaga
