import { call, put, takeEvery } from "redux-saga/effects"
import Moment from "moment"

// Class Redux States
import {
  GET_CLASSES,
  DELETE_CLASS,
  ADD_NEW_CLASS,
  GET_CLASS_DETAIL,
  UPDATE_CLASS,
  GET_CLASS_TYPES,
  RESET_CLASS,
  ADD_CLASS_NOTES,
  DELETE_CLASS_NOTES,
  GET_CLASS_HAPPENING_TYPES,
  GET_CLASS_HAPPENING,
  ADD_CLASS_HAPPENING,
  DELETE_CLASS_HAPPENING,
} from "./actionTypes"
import {
  getClassesFail,
  getClassesSuccess,
  deleteClassSuccess,
  deleteClassFail,
  addClassSuccess,
  addClassFail,
  getClassDetailSuccess,
  getClassDetailFail,
  updateClassSuccess,
  updateClassFail,
  getClassTypesSuccess,
  getClassTypesFail,
  resetClassSuccess,
  addClassNoteSuccess,
  addClassNoteFail,
  deleteClassNoteSuccess,
  deleteClassNoteFail,
  getClassHappeningTypesSuccess,
  getClassHappeningTypesFail,
  getClassHappeningsSuccess,
  getClassHappeningsFail,
  addClassHappeningSuccess,
  addClassHappeningFail,
  deleteClassHappeningSuccess,
  deleteClassHappeningFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getClasses,
  deleteClass,
  addNewClass,
  getClassDetail,
  updateClass,
  getClassTypes,
  addClassNote,
  deleteClassNote,
  getClassHappeningTypes,
  getClassHappenings,
  deleteClassHappening,
  addClassHappening,
} from "helpers/backend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function* fetchClasses() {
  try {
    const response = yield call(getClasses)
    yield put(getClassesSuccess(response))
  } catch (error) {
    yield put(getClassesFail(error))
  }
}

function* onDeleteClass({ payload: class1 }) {
  try {
    const response = yield call(deleteClass, class1)
    yield put(deleteClassSuccess(response))
    toast.success("Class deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteClassFail(error))
    toast.error("Class deletion failed", { autoClose: 2000 })
  }
}

function* onAddNewClass({ payload: class1 }) {
  try {
    const request = {
      ...class1,
      ClassTypeID: class1.ClassTypes.ClassTypeID,
      RoomID: class1.Rooms ? class1.Rooms.RoomID : null,
    }

    const response = yield call(addNewClass, request)
    yield put(addClassSuccess(response[0]))
    toast.success("Class added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addClassFail(error))
    toast.error("Class addition failed", { autoClose: 2000 })
  }
}

function* fetchClassDetail({ classId }) {
  try {
    const response = yield call(getClassDetail, classId)
    let result = response[0]

    result = {
      ...result,
      ClassTypes: {
        ClassTypeID: result.ClassTypeID,
        ClassType: result.ClassType,
      },
      Rooms: {
        RoomID: result.RoomID,
        Title: result.Room,
      },
    }
    yield put(getClassDetailSuccess(result))
  } catch (error) {
    yield put(getClassDetailFail(error))
  }
}

function* onUpdateClass({ payload: class1 }) {
  try {
    const request = {
      ...class1,
      ClassTypeID: class1.ClassTypes.ClassTypeID,
      RoomID: class1.Rooms.RoomID,
    }

    const response = yield call(updateClass, request)
    let result = response[0]

    yield put(updateClassSuccess(result))
    toast.success("Class updated successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateClassFail(error))
    toast.error("Class updation failed", { autoClose: 2000 })
  }
}

function* fetchClassTypes() {
  try {
    const response = yield call(getClassTypes)
    yield put(getClassTypesSuccess(response))
  } catch (error) {
    yield put(getClassTypesFail(error))
  }
}

function* onResetClass() {
  try {
    yield put(resetClassSuccess({}))
  } catch (error) {}
}

function* onAddClassNote({ payload: note }) {
  try {
    const response = yield call(addClassNote, note)
    yield put(addClassNoteSuccess(response[0]))
    toast.success("Class note added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addClassNoteFail(error))
    toast.error("Class note addition failed", { autoClose: 2000 })
  }
}

function* onDeleteClassNote({ payload: classNoteId }) {
  try {
    const response = yield call(deleteClassNote, classNoteId)
    yield put(deleteClassNoteSuccess(response))
    toast.success("Class note deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteClassNoteFail(error))
    toast.error("Class note deletion failed", { autoClose: 2000 })
  }
}

function* fetchClassHapeningTypes() {
  try {
    const response = yield call(getClassHappeningTypes)
    yield put(getClassHappeningTypesSuccess(response))
  } catch (error) {
    yield put(getClassHappeningTypesFail(error))
  }
}

function* fetchClassHapenings({ payload: { classID, filterDate } }) {
  try {
    const class1 = {
      classID: classID,
      filterDate: filterDate,
    }
    const response = yield call(getClassHappenings, class1)
    yield put(getClassHappeningsSuccess(response))
  } catch (error) {
    yield put(getClassHappeningsFail(error))
  }
}

function* onDeleteClassHappening({ payload: classHappeningID }) {
  try {
    const response = yield call(deleteClassHappening, classHappeningID)
    yield put(deleteClassHappeningSuccess(response))
    toast.success("deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteClassHappeningFail(error))
    toast.error("deletion failed", { autoClose: 2000 })
  }
}

function* onAddClassHappening({ payload: classHappening }) {
  try {
    const response = yield call(addClassHappening, classHappening)
    yield put(addClassHappeningSuccess(response))
    toast.success("Happening added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addClassHappeningFail(error))
    toast.error("Happening failed", { autoClose: 2000 })
  }
}

function* classSaga() {
  yield takeEvery(GET_CLASSES, fetchClasses)
  yield takeEvery(DELETE_CLASS, onDeleteClass)
  yield takeEvery(ADD_NEW_CLASS, onAddNewClass)
  yield takeEvery(GET_CLASS_DETAIL, fetchClassDetail)
  yield takeEvery(UPDATE_CLASS, onUpdateClass)
  yield takeEvery(GET_CLASS_TYPES, fetchClassTypes)
  yield takeEvery(RESET_CLASS, onResetClass)
  yield takeEvery(ADD_CLASS_NOTES, onAddClassNote)
  yield takeEvery(DELETE_CLASS_NOTES, onDeleteClassNote)

  //  Class Happening
  yield takeEvery(GET_CLASS_HAPPENING_TYPES, fetchClassHapeningTypes)
  yield takeEvery(GET_CLASS_HAPPENING, fetchClassHapenings)
  yield takeEvery(ADD_CLASS_HAPPENING, onAddClassHappening)
  yield takeEvery(DELETE_CLASS_HAPPENING, onDeleteClassHappening)
}

export default classSaga
