import React, { useEffect, useMemo, useState, Suspense } from "react"
import { useDeepCompareEffect } from "hooks"
import PropTypes from "prop-types"
import { Link, json, useNavigate } from "react-router-dom"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../../components/Common/TableContainer"

//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import DeleteModal from "../../../components/Common/DeleteModal"
// import HappeningModal from "../modal/HappeningModal"
// import JoinClassModal from "../modal/JoinClassModal"
// import LeaveClassModal from "../modal/LeaveClassModal"
import {
  getClassHappenings as onGetClassHappenings,
  getClassHappeningTypes as onGetClassHappeningTypes,
  deleteClassHappening as onDeleteClassHappening,
} from "store/actions"
import Moment from "moment"
import Flatpickr from "react-flatpickr"
//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import {
  Badge,
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Card,
  CardBody,
} from "reactstrap"

import Spinners from "components/Common/Spinner"
import { getMinute, getHour } from "helpers/duration_helper"
import HappeningModal from "../modal/HappeningModal"
import DropdownButton from "components/Common/DropdownButton"
import EmptyContainer from "components/Common/EmptyContainer"

function HappeningTab() {
  const [toggleMenu, setToggleMenu] = useState(false)
  const dispatch = useDispatch()

  const { classhappenings, class1, happeningtypes, loading } = useSelector(
    state => state.class1
  )

  const [isLoading, setLoading] = useState(loading)
  const [deleteModal, setDeleteModal] = useState(false)
  const [classHappeningID, setClassHappeningID] = useState(null)
  const [happeningTypeID, setHappeningTypeID] = useState(0)
  const [happeningModal, setHappeningModal] = useState(false)
  const [filterDate, setFilterDate] = useState(Moment().format("DD-MM-yyyy"))

  useDeepCompareEffect(() => {
    dispatch(onGetClassHappeningTypes())
  }, [dispatch])

  useEffect(() => {
    dispatch(onGetClassHappenings(class1.ClassID, filterDate))
  }, [dispatch, filterDate])

  const onHappeningClick = obj => {
    debugger
    setHappeningTypeID(obj.ClassHappeningTypeID)
    setHappeningModal(true)
  }

  const onClickDelete = arg => {
    setClassHappeningID(arg.ClassHappeningID)
    setDeleteModal(true)
  }

  const handleDelete = () => {
    if (classHappeningID) {
      dispatch(onDeleteClassHappening(classHappeningID))
      setDeleteModal(false)
    }
  }
  const handleValidDate = date => {
    return Moment(new Date(date)).format("DD MMM Y")
  }

  const onClickEdit = arg => {
    setClassHappeningID(arg.ClassHappeningID)
    setHappeningTypeID(arg.ClassHappeningTypeID)
    setHappeningModal(true)
  }

  const onModalClose = () => {
    setHappeningModal(false)
    setClassHappeningID(null)
    setHappeningTypeID(null)
  }

  const columns = useMemo(
    () => [
      {
        Header: "Happening",
        accessor: "ClassHappeningType",
        Cell: cellProps => {
          return cellProps.value ? cellProps.value : ""
        },
      },
      {
        Header: "Event Time",
        accessor: "HappeningDate",
        Cell: cellProps => {
          return <>{cellProps.row.original.HappeningDate}</>
        },
      },
      {
        Header: "Location",
        accessor: "Title",
        Cell: cellProps => {
          return <>{cellProps.row.original.Title}</>
        },
      },
      {
        Header: "Updated",
        Cell: cellProps => {
          return (
            <>
              <span className="font-size-14 mb-1">
                {cellProps.row.original.CreatedByName}
              </span>
              <p className="text-muted mb-0">
                {handleValidDate(cellProps.row.original.CreatedDate)}
              </p>
            </>
          )
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                hidden={cellProps.row.original.Resolved}
                to="#"
                // to={"/staff/" + cellProps.row.original.RowID}
                className="text-success"
                onClick={() => {
                  onClickEdit(cellProps.row.original)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  onClickDelete(cellProps.row.original)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )
  return (
    <React.Fragment>
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDelete}
          onCloseClick={() => setDeleteModal(false)}
        />
      )}

      {happeningModal && (
        <HappeningModal
          show={happeningModal}
          onCloseClick={() => onModalClose()}
          classHappeningID={classHappeningID}
          happeningTypeID={happeningTypeID}
        />
      )}

      <div className="text-sm-end"></div>
      {isLoading ? (
        <Spinners setLoading={setLoading} />
      ) : (
        <>
          <Row className="mb-2 justify-content-end">
            <Col sm="6" lg="4">
              <div className="mb-3 d-flex">
                <Flatpickr
                  className="form-control me-3"
                  id="FilterDate"
                  options={{
                    dateFormat: "d M, Y",
                  }}
                  value={Moment().format("DD MMM yyyy")}
                  onChange={(selectedDates, dateStr, instance) => {
                    setFilterDate(dateStr)
                  }}
                />
                <DropdownButton
                  label="Add Class"
                  items={happeningtypes.filter(x => x.Alert == 0)}
                  textField="ClassHappeningType"
                  valueField="ClassHappeningTypeID"
                  onSelectClick={e => onHappeningClick(e)}
                />
                <DropdownButton
                  label="Add Class Alerts"
                  items={happeningtypes.filter(x => x.Alert == 1)}
                  textField="ClassHappeningType"
                  valueField="ClassHappeningTypeID"
                  onSelectClick={e => onHappeningClick(e)}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              {classhappenings?.length == 0 ? (
                <EmptyContainer
                  backURL=""
                  message="No happenings found for class"
                  linkText=""
                />
              ) : (
                <TableContainer
                  columns={columns}
                  data={classhappenings}
                  isGlobalFilter={true}
                  customPageSize={10}
                  isPagination={true}
                  filterable={false}
                  iscustomPageSizeOptions={true}
                  tableClass="align-middle table-nowrap table-check"
                  theadClass="table-light"
                  pagination="pagination pagination-rounded justify-content-end mb-2"
                />
              )}
            </Col>
          </Row>
        </>
      )}
    </React.Fragment>
  )
}
HappeningTab.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default HappeningTab
