import {
  GET_NOTES_FAIL,
  GET_NOTES_SUCCESS,
  DELETE_NOTE_SUCCESS,
  DELETE_NOTE_FAIL,
  ADD_NOTE_FAIL,
  ADD_NOTE_SUCCESS,
  GET_NOTE_DETAIL_SUCCESS,
  GET_NOTE_DETAIL_FAIL,
  UPDATE_NOTE_SUCCESS,
  UPDATE_NOTE_FAIL,
  RESET_NOTE_SUCCESS,
  GET_NOTECATEGORIES_FAIL,
  GET_NOTECATEGORIES_SUCCESS,
  ADD_NOTE_SCHEDULE_FAIL,
  ADD_NOTE_SCHEDULE_SUCCESS,
  GET_NOTESCHEDULES_BY_NOTE_SUCCESS,
  GET_NOTESCHEDULES_BY_NOTE_FAIL,
  UPDATE_NOTESCHEDULE_DETAIL_SUCCESS,
  UPDATE_NOTESCHEDULE_DETAIL_FAIL,
  GET_NOTESCHEDULE_DETAIL_BYNSD_SUCCESS,
  GET_NOTESCHEDULE_DETAIL_BYNSD_FAIL,
  DELETE_NOTE_SCHEDULE_SUCCESS,
  DELETE_NOTE_SCHEDULE_FAIL,
  DELETE_NOTE_SCHEDULE_DETAIL_SUCCESS,
  DELETE_NOTE_SCHEDULE_DETAIL_FAIL,
  
} from "./actionTypes"

const INIT_STATE = {
  notes: [],
  note: {},
  notecategories:[],
  noteschedules:[],
  notescheduleDetails:[],
  error: {},
  loading: true
}

const Note = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_NOTES_SUCCESS:
      return {
        ...state,
        notes: action.payload,
        loading: true,
      }
    case GET_NOTES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_NOTE_SUCCESS: {
      const data = {
        ...state,
        notes: state.notes.filter(
          note => note.NoteID !== parseInt(action.payload)
        ),
      }
      return {
        ...state,
        notes: state.notes.filter(
          note => note.NoteID !== parseInt(action.payload)
        ),
      }
    }
    case DELETE_NOTE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_NOTE_SUCCESS:
      return {
        ...state,
        notes: [...state.notes, action.payload],
      }
    case ADD_NOTE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_NOTE_DETAIL_SUCCESS:
      return {
        ...state,
        note: action.payload,
        loading: true,
      }

    case GET_NOTE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_NOTE_SUCCESS:
      return {
        ...state,
        notes: state.notes.map(note =>
          note.NoteID.toString() === action.payload.NoteID.toString()
            ? { note, ...action.payload }
            : note
        ),
      }
    case UPDATE_NOTE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

      case RESET_NOTE_SUCCESS:
      return {
        ...state,
        note: action.payload,
      }
    case GET_NOTECATEGORIES_SUCCESS:
      return {
        ...state,
        notecategories: action.payload,
        loading: true,
      }
    case GET_NOTECATEGORIES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_NOTE_SCHEDULE_SUCCESS:
      return {
        ...state,
        noteschedules: [...state.noteschedules, action.payload],
      }
    case ADD_NOTE_SCHEDULE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_NOTESCHEDULES_BY_NOTE_SUCCESS:
      return {
        ...state,
        noteschedules: action.payload,
        loading: true,
      }
    case GET_NOTESCHEDULES_BY_NOTE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_NOTESCHEDULE_DETAIL_SUCCESS:
      return {
        noteschedules: [
          ...state.noteschedules.map(noteschedule =>
            noteschedules.NoteScheduleID.toString() ===
            action.payload.NoteScheduleID.toString()
              ? { noteschedule, ...action.payload }
              : noteschedule
          ),
        ],
      }
    case UPDATE_NOTESCHEDULE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_NOTESCHEDULE_DETAIL_BYNSD_SUCCESS:
      return {
        ...state,
        notescheduleDetails: action.payload,
        loading: true,
      }
    case GET_NOTESCHEDULE_DETAIL_BYNSD_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_NOTE_SCHEDULE_SUCCESS: {
      return {
        ...state,
        noteschedules: state.noteschedules.filter(
          noteschedule =>
            noteschedule.NoteScheduleID !== parseInt(action.payload)
        ),
      }
    }
    case DELETE_NOTE_SCHEDULE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_NOTE_SCHEDULE_DETAIL_SUCCESS: {
      return {
        ...state,
        notescheduleDetails: state.notescheduleDetails.filter(
          notescheduledetail =>
            notescheduledetail.NoteScheduleDetailID !==
            parseInt(action.payload)
        ),
      }
    }
    case DELETE_NOTE_SCHEDULE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default Note
