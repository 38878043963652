import {
  GET_ACTIVITIES,
  GET_ACTIVITIES_SUCCESS,
  GET_ACTIVITIES_FAIL,
  ADD_ACTIVITY,
  ADD_ACTIVITY_SUCCESS,
  ADD_ACTIVITY_FAIL,
  DELETE_ACTIVITY,
  DELETE_ACTIVITY_SUCCESS,
  DELETE_ACTIVITY_FAIL,
  GET_ACTIVITY_DETAIL,
  GET_ACTIVITY_DETAIL_SUCCESS,
  GET_ACTIVITY_DETAIL_FAIL,
  UPDATE_ACTIVITY,
  UPDATE_ACTIVITY_SUCCESS,
  UPDATE_ACTIVITY_FAIL,
} from "./actionTypes"

export const getActivities = filter => ({
  type: GET_ACTIVITIES,
  filter,
})

export const getActivitiesSuccess = activities => ({
  type: GET_ACTIVITIES_SUCCESS,
  payload: activities,
})

export const getActivitiesFail = error => ({
  type: GET_ACTIVITIES_FAIL,
  payload: error,
})

export const addActivitiy = activity => ({
  type: ADD_ACTIVITY,
  payload: activity,
})

export const addActivitiySuccess = activity => ({
  type: ADD_ACTIVITY_SUCCESS,
  payload: activity,
})

export const addActivitiyFail = error => ({
  type: ADD_ACTIVITY_FAIL,
  payload: error,
})

export const deleteActivity = activity => ({
  type: DELETE_ACTIVITY,
  payload: activity,
})

export const deleteActivitySuccess = activity => ({
  type: DELETE_ACTIVITY_SUCCESS,
  payload: activity,
})

export const deleteActivityFail = error => ({
  type: DELETE_ACTIVITY_FAIL,
  payload: error,
})

export const getActivityDetail = activityId => ({
  type: GET_ACTIVITY_DETAIL,
  payload: activityId,
})

export const getActivityDetailSuccess = activity => ({
  type: GET_ACTIVITY_DETAIL_SUCCESS,
  payload: activity,
})

export const getActivityDetailFail = error => ({
  type: GET_ACTIVITY_DETAIL_FAIL,
  payload: error,
})

export const updateActivitiy = activity => ({
  type: UPDATE_ACTIVITY,
  payload: activity,
})

export const updateActivitiySuccess = activity => ({
  type: UPDATE_ACTIVITY_SUCCESS,
  payload: activity,
})

export const updateActivitiyFail = error => ({
  type: UPDATE_ACTIVITY_FAIL,
  payload: error,
})
