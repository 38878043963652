import {
  GET_PROGRAMS_FAIL,
  GET_PROGRAMS_SUCCESS,
  DELETE_PROGRAM_SUCCESS,
  DELETE_PROGRAM_FAIL,
  ADD_PROGRAM_FAIL,
  ADD_PROGRAM_SUCCESS,
  GET_PROGRAM_DETAIL_SUCCESS,
  GET_PROGRAM_DETAIL_FAIL,
  UPDATE_PROGRAM_SUCCESS,
  UPDATE_PROGRAM_FAIL,
  GET_PROGRAM_SCHEDULE_DETAIL_SUCCESS,
  GET_PROGRAM_SCHEDULE_DETAIL_FAIL,
  ADD_ENROLLMENT_SUCCESS,
  ADD_ENROLLMENT_FAIL,
  GET_ENROLLMENTS_SUCCESS,
  GET_ENROLLMENTS_FAIL,
  UPDATE_ENROLLMENT_SUCCESS,
  UPDATE_ENROLLMENT_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  programs: [],
  program: {},
  enrollments: [],
  error: {},
  loading: true,
}

const Program = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROGRAMS_SUCCESS:
      return {
        ...state,
        programs: action.payload,
        loading: true,
      }
    case GET_PROGRAMS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_PROGRAM_SUCCESS: {
      const data = {
        ...state,
        programs: state.programs.filter(
          program => program.ProgramID !== parseInt(action.payload)
        ),
      }
      return {
        ...state,
        programs: state.programs.filter(
          program => program.ProgramID !== action.payload
        ),
      }
    }
    case DELETE_PROGRAM_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_PROGRAM_SUCCESS:
      return {
        ...state,
        programs: [...state.programs, action.payload],
      }
    case ADD_PROGRAM_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_PROGRAM_DETAIL_SUCCESS:
      return {
        ...state,
        program: action.payload,
        loading: true,
      }

    case GET_PROGRAM_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_PROGRAM_SUCCESS:
      return {
        ...state,
        programs: state.programs.map(program =>
          program.ProgramID.toString() === action.payload.ProgramID.toString()
            ? { program, ...action.payload }
            : program
        ),
      }
    case UPDATE_PROGRAM_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_PROGRAM_SCHEDULE_DETAIL_SUCCESS:
      return {
        ...state,
        program: action.payload,
        loading: true,
      }
    case GET_PROGRAM_SCHEDULE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_ENROLLMENT_SUCCESS:
      return {
        ...state,
        programs: action.payload,
      }
    case ADD_ENROLLMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_ENROLLMENTS_SUCCESS:
      return {
        ...state,
        enrollments: action.payload,
        loading: true,
      }
    case GET_ENROLLMENTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_ENROLLMENT_SUCCESS:
      return {
        ...state,
        enrollments: action.payload,
        loading: true,
      }
    case UPDATE_ENROLLMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default Program
