import React, { useEffect } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import * as moment from "moment"

import { updateMessageApproval as onUpdateMessageApproval } from "store/actions"
import { getMessageFileDownloadPath } from "helpers/imageService"
import { isEmpty } from "lodash"

const AwaitingModal = ({ show, message, onCloseClick }) => {
  const dispatch = useDispatch()
  const { messageposts, loading } = useSelector(state => state.child)

  useEffect(() => {
    console.log(message)
  }, [])

  const onSaveClick = e => {
    let request = {
      ...message,
      Approved: e,
    }
    dispatch(onUpdateMessageApproval(request))
    onCloseClick()
  }

  return (
    <Modal isOpen={show} toggle={onCloseClick} size="lg" centered={true}>
      <div className="modal-content">
        <ModalHeader className="d-flex justify-content-center">
          Approve Message
        </ModalHeader>
        <ModalBody>
          {!isEmpty(message) ? (
            <>
              <p className="text-center alert alert-success fade show">
                Created by {message.CreatedByName} on{" "}
                {moment(message.CreatedDate).format("DD MMM Y, hh:mm A")}
              </p>
              <p>
                <b>Topic : </b> {message.Topic}
              </p>
              <p>
                <b>Message : </b>{" "}
                {messageposts[0].Message && messageposts[0].Message}
              </p>
              <p>
                <b>Attachment(s) :</b>{" "}
                {messageposts[0].Attachments &&
                  (messageposts[0].Attachments || []).map((attachment, e) => {
                    return (
                      <a
                        key={"attachment" + e}
                        href={getMessageFileDownloadPath(
                          attachment.RowID,
                          attachment.FileName
                        )}
                      >
                        {attachment.FileName}
                        {e === messageposts[0].Attachments.length - 1
                          ? ""
                          : ", "}
                      </a>
                    )
                  })}
              </p>
            </>
          ) : (
            ""
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            color="btn btn-outline-dark"
            onClick={onCloseClick}
          >
            Close
          </Button>
          <Button
            type="button"
            color="btn btn-outline-danger"
            onClick={() => onSaveClick(0)}
          >
            Decline
          </Button>
          <Button
            type="button"
            color="btn btn-outline-primary"
            onClick={() => onSaveClick(1)}
          >
            Release
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

AwaitingModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.bool,
  message: PropTypes.any,
}

export default AwaitingModal
