
/**
 * Add LESSONS
 */

export const GET_LESSONS = "GET_LESSONS"
export const GET_LESSONS_SUCCESS = "GET_LESSONS_SUCCESS"
export const GET_LESSONS_FAIL = "GET_LESSONS_FAIL"


export const DELETE_LESSON = "DELETE_LESSON"
export const DELETE_LESSON_FAIL = "DELETE_LESSON_FAIL"
export const DELETE_LESSON_SUCCESS = "DELETE_LESSON_SUCCESS"

export const ADD_LESSON = "ADD_LESSON"
export const ADD_LESSON_SUCCESS = "ADD_LESSON_SUCCESS"
export const ADD_LESSON_FAIL = "ADD_LESSON_FAIL"

export const GET_LESSON_DETAIL = "GET_LESSON_DETAIL"
export const GET_LESSON_DETAIL_SUCCESS = "GET_LESSON_DETAIL_SUCCESS"
export const GET_LESSON_DETAIL_FAIL = "GET_LESSON_DETAIL_FAIL"

export const UPDATE_LESSON = "UPDATE_LESSON"
export const UPDATE_LESSON_SUCCESS = "UPDATE_LESSON_SUCCESS"
export const UPDATE_LESSON_FAIL = "UPDATE_LESSON_FAIL"
/**
 * Reset LESSON
 */
export const RESET_LESSON = "RESET_LESSON"
export const RESET_LESSON_SUCCESS = "RESET_LESSON_SUCCESS"

export const GET_NOTECATEGORIES = "GET_NOTECATEGORIES"
export const GET_NOTECATEGORIES_SUCCESS = "GET_NOTECATEGORIES_SUCCESS"
export const GET_NOTECATEGORIES_FAIL = "GET_NOTECATEGORIES_FAIL"

// ADD LESSON Schedules


export const ADD_LESSON_SCHEDULE = "ADD_LESSON_SCHEDULE"
export const ADD_LESSON_SCHEDULE_SUCCESS = "ADD_LESSON_SCHEDULE_SUCCESS"
export const ADD_LESSON_SCHEDULE_FAIL = "ADD_LESSON_SCHEDULE_FAIL"

export const DELETE_LESSON_SCHEDULE = "DELETE_LESSON_SCHEDULE"
export const DELETE_LESSON_SCHEDULE_SUCCESS = "DELETE_LESSON_SCHEDULE_SUCCESS"
export const DELETE_LESSON_SCHEDULE_FAIL = "DELETE_LESSON_SCHEDULE_FAIL"

//getLESSONSchedulesByLESSON
export const GET_LESSONSCHEDULES_BY_LESSON = "GET_LESSONSCHEDULES_BY_LESSON"
export const GET_LESSONSCHEDULES_BY_LESSON_SUCCESS = "GET_LESSONSCHEDULES_BY_LESSON_SUCCESS"
export const GET_LESSONSCHEDULES_BY_LESSON_FAIL = "GET_LESSONSCHEDULES_BY_LESSON_FAIL"

// Update LESSONScheduleDetails Details
export const UPDATE_LESSONSCHEDULE_DETAIL = "UPDATE_LESSONSCHEDULE_DETAIL"
export const UPDATE_LESSONSCHEDULE_DETAIL_SUCCESS = "UPDATE_LESSONSCHEDULE_DETAIL_SUCCESS"
export const UPDATE_LESSONSCHEDULE_DETAIL_FAIL = "UPDATE_LESSONSCHEDULE_DETAIL_FAIL"


export const GET_LESSONSCHEDULE_DETAIL_BYLSD = "GET_LESSONSCHEDULE_DETAIL_BYLSD"
export const GET_LESSONSCHEDULE_DETAIL_BYLSD_SUCCESS = "GET_LESSONSCHEDULE_DETAIL_BYLSD_SUCCESS"
export const GET_LESSONSCHEDULE_DETAIL_BYLSD_FAIL = "GET_LESSONSCHEDULE_DETAIL_BYLSD_FAIL"

export const DELETE_LESSON_SCHEDULE_DETAIL = "DELETE_LESSON_SCHEDULE_DETAIL"
export const DELETE_LESSON_SCHEDULE_DETAIL_SUCCESS = "DELETE_LESSON_SCHEDULE_DETAIL_SUCCESS"
export const DELETE_LESSON_SCHEDULE_DETAIL_FAIL = "DELETE_LESSON_SCHEDULE_DETAIL_FAIL"
