import { Categories } from "emoji-picker-react"
import {
  GET_CURRICULUMS,
  GET_CURRICULUMS_FAIL,
  GET_CURRICULUMS_SUCCESS,
  DELETE_CURRICULUM,
  DELETE_CURRICULUM_FAIL,
  DELETE_CURRICULUM_SUCCESS,
  ADD_CURRICULUM,
  ADD_CURRICULUM_SUCCESS,
  ADD_CURRICULUM_FAIL,
  GET_CURRICULUM_DETAIL,
  GET_CURRICULUM_DETAIL_SUCCESS,
  GET_CURRICULUM_DETAIL_FAIL,
  UPDATE_CURRICULUM,
  UPDATE_CURRICULUM_SUCCESS,
  UPDATE_CURRICULUM_FAIL,
  GET_NOTECATEGORIES,
  GET_NOTECATEGORIES_FAIL,
  GET_NOTECATEGORIES_SUCCESS,
  RESET_CURRICULUM_SUCCESS,
  RESET_CURRICULUM,
  ADD_CURRICULUM_SCHEDULE,
  ADD_CURRICULUM_SCHEDULE_SUCCESS,
  ADD_CURRICULUM_SCHEDULE_FAIL,
  DELETE_CURRICULUM_SCHEDULE,
  DELETE_CURRICULUM_SCHEDULE_SUCCESS,
  DELETE_CURRICULUM_SCHEDULE_FAIL,
  GET_CURRICULUMSHEDULES_BY_CURRICULUM,
  GET_CURRICULUMSHEDULES_BY_CURRICULUM_SUCCESS,
  GET_CURRICULUMSHEDULES_BY_CURRICULUM_FAIL,
  UPDATE_CURRICULUMSHEDULE_DETAIL,
  UPDATE_CURRICULUMSHEDULE_DETAIL_SUCCESS,
  UPDATE_CURRICULUMSHEDULE_DETAIL_FAIL,
  GET_CURRICULUMSHEDULE_DETAIL_BYCSD,
  GET_CURRICULUMSHEDULE_DETAIL_BYCSD_SUCCESS,
  GET_CURRICULUMSHEDULE_DETAIL_BYCSD_FAIL,
  DELETE_CURRICULUM_SCHEDULE_DETAIL,
  DELETE_CURRICULUM_SCHEDULE_DETAIL_SUCCESS,
  DELETE_CURRICULUM_SCHEDULE_DETAIL_FAIL
} from "./actionTypes"

//  Curriculum

export const getCurriculums = () => ({
  type: GET_CURRICULUMS,
})

export const getCurriculumsSuccess = curriculums => ({
  type: GET_CURRICULUMS_SUCCESS,
  payload: curriculums,
})

export const getCurriculumsFail = error => ({
  type: GET_CURRICULUMS_FAIL,
  payload: error,
})

export const deleteCurriculum = curriculumID => ({
  type: DELETE_CURRICULUM,
  payload: curriculumID,
})

export const deleteCurriculumSuccess = curriculum => ({
  type: DELETE_CURRICULUM_SUCCESS,
  payload: curriculum,
})

export const deleteCurriculumFail = error => ({
  type: DELETE_CURRICULUM_FAIL,
  payload: error,
})

export const addCurriculum = curriculum => ({
  type: ADD_CURRICULUM,
  payload: curriculum,
})

export const addCurriculumSuccess = curriculum => ({
  type: ADD_CURRICULUM_SUCCESS,
  payload: curriculum,
})

export const addCurriculumFail = error => ({
  type: ADD_CURRICULUM_FAIL,
  payload: error,
})

export const getCurriculumDetail = RowID => ({
  type: GET_CURRICULUM_DETAIL,
  RowID,
})

export const getCurriculumDetailSuccess = curriculum => ({
  type: GET_CURRICULUM_DETAIL_SUCCESS,
  payload: curriculum,
})

export const getCurriculumDetailFail = error => ({
  type: GET_CURRICULUM_DETAIL_FAIL,
  payload: error,
})

export const updateCurriculum = curriculum => ({
  type: UPDATE_CURRICULUM,
  payload: curriculum,
})

export const updateCurriculumSuccess = curriculum => ({
  type: UPDATE_CURRICULUM_SUCCESS,
  payload: curriculum,
})

export const updateCurriculumFail = error => ({
  type: UPDATE_CURRICULUM_FAIL,
  payload: error,
})

export const resetCurriculum = () => ({
  type: RESET_CURRICULUM,
})

export const resetCurriculumSuccess = curriculum => ({
  type: RESET_CURRICULUM_SUCCESS,
  payload: curriculum,
})
export const getNoteCategories= () => ({
  type: GET_NOTECATEGORIES,
})

export const getNoteCategoriesSuccess = notecategories => ({
  type: GET_NOTECATEGORIES_SUCCESS,
  payload: notecategories,
})

export const getNoteCategoriesFail = error => ({
  type: GET_NOTECATEGORIES_FAIL,
  payload: error,
})


export const addCurriculumSchedule = curriculumschedule => ({
  type: ADD_CURRICULUM_SCHEDULE,
  payload: curriculumschedule,
})

export const addCurriculumScheduleSuccess = curriculumschedule => ({
  type: ADD_CURRICULUM_SCHEDULE_SUCCESS,
  payload: curriculumschedule,
})

export const addCurriculumScheduleFail = error => ({
  type: ADD_CURRICULUM_SCHEDULE_FAIL,
  payload: error,
})


//  Curriculum schedules

export const getCurriculumSchedulesByCurriculum = (RowID) => ({
  type: GET_CURRICULUMSHEDULES_BY_CURRICULUM,
  RowID
})

export const getCurriculumSchedulesByCurriculumSuccess = curriculums => ({
  type: GET_CURRICULUMSHEDULES_BY_CURRICULUM_SUCCESS,
  payload: curriculums,
})

export const getCurriculumSchedulesByCurriculumFail = error => ({
  type: GET_CURRICULUMSHEDULES_BY_CURRICULUM_FAIL,
  payload: error,
})

export const updateCurriculumScheduleDetail = curriculumScheduleDetail => ({
  type: UPDATE_CURRICULUMSHEDULE_DETAIL,
  payload: curriculumScheduleDetail
  
})

export const updateCurriculumScheduleDetailSuccess = curriculumScheduleDetail => ({
  type: UPDATE_CURRICULUMSHEDULE_DETAIL_SUCCESS,
  payload: curriculumScheduleDetail,
})

export const updateCurriculumScheduleDetailFail = error => ({
  type: UPDATE_CURRICULUMSHEDULE_DETAIL_FAIL,
  payload: error,
})


export const getCurriculumScheduleDetailbyCSD = curriculumScheduleDetail => ({
  type: GET_CURRICULUMSHEDULE_DETAIL_BYCSD,
  payload: curriculumScheduleDetail,
})

export const getCurriculumScheduleDetailbyCSDSuccess = curriculumScheduleDetail => ({
  type: GET_CURRICULUMSHEDULE_DETAIL_BYCSD_SUCCESS,
  payload: curriculumScheduleDetail,
})

export const getCurriculumScheduleDetailbyCSDFail = error => ({
  type: GET_CURRICULUMSHEDULE_DETAIL_BYCSD_FAIL,
  payload: error,
})

export const deleteCurriculumSchedule = curriculumScheduleID => ({
  type: DELETE_CURRICULUM_SCHEDULE,
  payload: curriculumScheduleID,
})

export const deleteCurriculumScheduleSuccess = curriculumScheduleID => ({
  type: DELETE_CURRICULUM_SCHEDULE_SUCCESS,
  payload: curriculumScheduleID,
})

export const deleteCurriculumScheduleFail = error => ({
  type: DELETE_CURRICULUM_SCHEDULE_FAIL,
  payload: error,
})



export const deleteCurriculumScheduleDetail = curriculumScheduleDetailID => ({
  type: DELETE_CURRICULUM_SCHEDULE_DETAIL,
  payload: curriculumScheduleDetailID,
})

export const deleteCurriculumScheduleDetailSuccess = curriculumScheduleDetailID => ({
  type: DELETE_CURRICULUM_SCHEDULE_DETAIL_SUCCESS,
  payload: curriculumScheduleDetailID,
})

export const deleteCurriculumScheduleDetailFail = error => ({
  type: DELETE_CURRICULUM_SCHEDULE_DETAIL_FAIL,
  payload: error,
})